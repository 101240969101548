import React from "react";

const Error = ({setRequestError, header, paragraph}) => {
  return (
    <div
      className="sdds-message sdds-message__type-error sdds-message__icon-active sdds-message__extended-active sdds-message-ongrey"
      style={{
        position: "fixed",
        bottom: "4rem",
        left: "6rem",
        zIndex: 500,
        height: "100px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <svg
          className="sdds-message-icon sdds-message-icon--error"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 2.50019C5.85662 2.50019 2.49775 5.85906 2.49775 10.0024C2.49775 14.1458 5.85662 17.5047 10 17.5047C14.1434 17.5047 17.5023 14.1458 17.5023 10.0024C17.5023 5.85906 14.1434 2.50019 10 2.50019ZM1.25 10.0024C1.25 5.16995 5.16751 1.25244 10 1.25244C14.8325 1.25244 18.75 5.16995 18.75 10.0024C18.75 14.8349 14.8325 18.7524 10 18.7524C5.16751 18.7524 1.25 14.8349 1.25 10.0024Z"
            fill="#FF2340"
          ></path>
          <path
            d="M9.25175 9.044V6.25H10.7367V9.044L10.5167 11.728H9.47175L9.25175 9.044ZM9.21875 12.564H10.7807V14.06H9.21875V12.564Z"
            fill="#FF2340"
          ></path>
        </svg>

        <h4 className="sdds-message-single">{header}</h4>

        <p className="sdds-message-extended">
          {paragraph}
        </p>
        <i
          className="sdds-icon scania-cross"
          style={{
            position: "absolute",
            fontSize: "6rem",
            right: 1,
            top: 1,
            padding: "2rem",
            cursor: "pointer",
          }}
          onClick={(input)=>setRequestError(input)}
        ></i>
      </div>
    </div>
  );
};

export default Error;
