import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";

const RADIAN = Math.PI / 180;

const PieChartBEV = ({ data }) => {
  const color_mappings_BEV_product = {
    "Urban BEV 22 217":"#E4E9F1",
    "Urban BEV 22 120":"#BACDE8",
    "Regional BEV 23.11 468":"#87AFE8",
    "Regional BEV 23.11 312":"#4A89F3",
    "Urban BEV 24.06 468": "#2B70D3",
    "Urban BEV 24.06 312": "#2058A8",
    "Long Haulage BEV 24.11 468": "#16417F",
    "Long Haulage BEV 24.11 312": "#0F3263",
    "Long Haulage BEV 24.11 546": "#041E42",
    "Long Haulage BEV 24.11 387": "#001533",
    "Long Haulage BEV 24.11 231": "#450307",
    "Long Haulage BEV 25 624": "#6A080F",
    "Long Haulage BEV 26 624": "#950A16",
    "Next Gen BEV BEV 25.06 624": "#D6001C",
    "Construction BEV 25 390": "#DE2B36",
    "Urban BEV 25 234": "#F35359",
    "unused_3": "#F58E90",
    "unused_4": "#F8B6B7",
    "unused_5": "#FADBDC",
    "Next Gen BEV 26 624": "#FDF4F5"
  }

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      > 
        {`${data[index].value} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: "black" }}>{value}</span>;
  };

  /*
  useEffect(()=>{
    data.map((BEV_offer)=> {
      color_mappings_BEV_product[BEV_offer.name] = null
    });  
  }, [data])
  */

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={'100%'} height={'100%'}>
        <Tooltip />
        <Legend
          layout="vertical"
          align="center"
          formatter={renderColorfulLegendText}
        />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        > 
          {data.map((entry, index) => (
            console.log(entry),
            <Cell key={`cell-${index}`} fill={color_mappings_BEV_product[entry.name]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartBEV;
