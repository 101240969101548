import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons';

const Assumptions = () => {
  return (
    <div name="Assumptions" tab-key="tab-4">
      <div style={{ display:'flex', gap:'2rem', justifyContent:'flex-start'}}>
        <FontAwesomeIcon icon={faPersonDigging} size="2x" />
        <p>Under construction...</p>
      </div>
    </div>
  );
};

export default Assumptions;
