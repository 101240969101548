import React, { useEffect } from "react";

const sortOptions = ["PTO", "EC w/o PTO", "Total EC"];
export const complete_sortOptions = [
  "Daily distance, median",
  "Daily distance, longest",
  "Required battery percentage to complete median distance",
  "Required battery percentage to complete longest distance",
  "Intro year",
];

const Dropdown = ({ setOptions, options }) => {
  function handleSelectOption(event) {
    let newobj = [...options];
    newobj.push(event.detail.label);
    setOptions(newobj);
  }

  useEffect(() => {
    // component did mount
    document
      .querySelector("#sortDropdown")
      .addEventListener("selectOption", handleSelectOption);
  }, []);

  return (
    <>
      <sdds-dropdown
        id="sortDropdown"
        placeholder={"Choose feature ..."}
        style={{ width: "250px", display: "flex", alignItems: "center" }}
        label="Sort by ..."
        label-position="outside"
      >
        {sortOptions.map((option, index) => {
          return (
            <sdds-dropdown-option
              value={`option-${index + 1}`}
              className="hydrated"
              key={index}
            >
              {option}
            </sdds-dropdown-option>
          );
        })}
      </sdds-dropdown>
    </>
  );
};

export default Dropdown;
