import {
  getDefaultMiddleware,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import loginReducer from "../reducers/loginSlice";
import chassisReducer from "../reducers/chassisSlice";
import resultsReducer from "../reducers/resultsSlice";
import settingsSlice from "../reducers/settingsSlice";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

const rootPersistConfig = {
  key: "root",
  storage: storageSession, // by default local
};

const rootReducer = combineReducers({
  login: loginReducer,
  chassis: chassisReducer,
  results: resultsReducer,
  settings: settingsSlice,
});

// If multiple reducers aggregate the multiple reducers into one combined reducer to persist
// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

// persistor
export const persistor = persistStore(store);
