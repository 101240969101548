import React from "react";
import Login from "./Login";
import { selectResponse } from "../../reducers/resultsSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Export from "./Export";

const Header = () => {
  const response = useSelector(selectResponse);
  const location = useLocation();

  const condition =
    response !== undefined && Object.keys(response).length !== 0 && location.pathname === "/analysis";

  return (
    <div>
      <nav className="sdds-nav">
        <div
          className="sdds-nav__left"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="sdds-nav__app-name">
            FACT <span style={{ fontFamily: "monospace" }}>&nbsp;(beta)</span>
          </div>
          {condition && (
            <div
              style={{ height: "100%" }}
              className="sdds-divider-coloured-vertical"
            />
          )}
          {condition && (
            <div
              style={{
                height: "100%",
                color: "white",
                display: "flex",
                alignItems: "center",
                padding: "0 24px",
              }}
            >
              <p className="sdds-headline-05" style={{}}>
                {response.BEV}
              </p>
            </div>
          )}
        </div>
        <div className="sdds-nav__right">
          {condition && (
            <div className="sdds-nav__app-name">
              <Export />
            </div>
          )}
          {condition && (
            <div
              style={{ height: "100%" }}
              className="sdds-divider-coloured-vertical"
            />
          )}
          <div className="sdds-nav__app-name">
            <Login />
          </div>
          <a className="sdds-nav__item sdds-nav__app-logo" href="#"></a>
        </div>
      </nav>
    </div>
  );
};

export default Header;
