import React, { useState } from "react";
import Container from "../Common/Container";
import Sheet from "./Sheet";
import Summary from "./Summary";
import Charging from "./Charging";
import Assumptions from "./Assumptions";
import "./FactSheet.css";

const FactSheet = () => {
  return (
    <Container>
      <div
        className="sdds-block sdds-block__ongrey"
        style={{
          padding: "4rem",
          width: "90%",
          height: "100%",
          borderRadius: "2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <p className="sdds-headline-03" style={{ textAlign: "center" }}>
            Analysis results
          </p>
        </div>
        <sdds-inline-tabs className="tabs">
          <Summary />
          <Sheet/>
          <Assumptions />
        </sdds-inline-tabs>
      </div>
    </Container>
  );
};

export default FactSheet;
