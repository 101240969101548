import React, { useState, useEffect } from "react";
import { ptosettings, selectSettingsPTO } from "../../reducers/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as InfoLogo } from "../../images/Info.svg";
import { addResults } from "../../reducers/resultsSlice";
import { useMsal } from "@azure/msal-react";
import "./ModalPto.css";
import { selectResponse, selectNewBucketKey } from "../../reducers/resultsSlice";
import { loginRequest } from "../../services/auth-service";
import axios from "axios";

const pto_settings = {
  "Temperature controlled": null,
  Crane: "Enter crane load: ",
  Skiploader: "Enter number of cycles: ",
  "Hook lift": "Enter number of cycles: ",
  "Refuse collection": "Enter number of compartments: ",
  Tipper: "Enter number of cycles: ",
  "Tail lift": "Enter number of cycles: ",
};

const pto_conversion_fe_be = {
  "Temperature controlled": "temp_controlled",
  Crane: "crane",
  Skiploader: "skiploader",
  "Hook lift": "hooklift",
  "Refuse collection": "refuse",
  Tipper: "tipper",
  "Tail lift": "tail_lift",
};

const ModalPto = ({ setModalOpen, showSuccess, mode, chassis }) => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const responseObj = useSelector(selectResponse);
  const bucketKey = useSelector(selectNewBucketKey);
  const ptosetting = useSelector(selectSettingsPTO); // remove store reference
  // flexibility is added to its own component
  // only pto global settings and charging settings (BEV1/BEV3 top-up charging power and custom charging durations for each one)
  // perhaps bev1 30 min and 45 minutes, bev3 45 minutes and 1 hours or exactly the same

  const [pto, setPto] = useState("");
  const [ptoLoad, setptoLoad] = useState("");

  useEffect(() => {
    if (ptosetting.length !== 0) {
      if (mode === "all") {
        if (
          ptosetting.every(
            (obj) =>
              obj.pto === ptosetting[0].pto && obj.load == ptosetting[0].load
          ) &&
          chassis.length == ptosetting.length
        ) {
          setPto(ptosetting[0].pto);
          setptoLoad(ptosetting[0].load);
        }
      } else {
        ptosetting.forEach((chassi, idx) => {
          chassi.chassis_number === chassis.CHASSIS_NUMBER &&
            (setPto(chassi.pto), setptoLoad(chassi.load));
        });
      }
    }

    return () => {};
  }, []);

  const onClickHandler = (e) => {
    setPto(e.currentTarget.value);
  };

  const loadHandler = (e) => {
    setptoLoad(e.target.value);
  };

  const reCalculate = () => {
    const request_body = [];
    const list_chassis = mode === "all" ? responseObj.response : [chassis];
    if (pto === "Temperature controlled") {
      for (let chassis of list_chassis) {
        request_body.push({
          chassis_number: chassis["CHASSIS_NUMBER"],
          application: pto_conversion_fe_be[pto],
          avg_speeds: chassis["AVG_SPEED"],
        });
      }
    } else {
      for (let chassis of list_chassis) {
        request_body.push({
          chassis_number: chassis["CHASSIS_NUMBER"],
          application: pto_conversion_fe_be[pto],
          application_detail: parseInt(ptoLoad),
          median_daily_distance: chassis["DAILY_ODOMETER_MEDIAN"],
        });
      }
    }

    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    let endpoint = process.env.REACT_APP_FACT_API_GW_URL;
    const url = endpoint + "/Prod/UpdatePtoEc";

    instance.acquireTokenSilent(request).then((response) => {
      let id_token = response.idToken;
      let global_name = response.idTokenClaims.preferred_username.split("@")[0];
      let bucketPath = bucketKey

      const getChassisPTO = axios.create();
      getChassisPTO
        .patch(
          url,
          { request_body },
          {
            headers: {
              Authorization: `Bearer ${id_token}`,
              bucket_path: bucketPath,
            },
          }
        )
        .then((res) => {
          dispatch(
            addResults({
              response: {
                message: res.data.message,
                response: res.data.payload.response,
              },
            })
          );
          // json parse/loads and stringify/dumps to remove reference to the store since redux
          // otherwise complains that there cannot be any mutation to a state, only that a dispatch can alter the
          // slice of state itself...
          const new_ptolist =
            ptosetting.length !== 0
              ? JSON.parse(JSON.stringify(ptosetting))
              : [];

          let addbool = false;
          if (new_ptolist.length !== 0) {
            for (let chass of list_chassis) {
              addbool = false;
              for (let chass2 of new_ptolist) {
                if (chass.CHASSIS_NUMBER === chass2.chassis_number) {
                  chass2.pto = pto;
                  chass2.load = ptoLoad;
                  addbool = true;
                  break;
                }
              }
              !addbool &&
                new_ptolist.push({
                  chassis_number: chass.CHASSIS_NUMBER,
                  pto: pto,
                  load: ptoLoad,
                });
            }
          } else {
            for (let chass3 of list_chassis) {
              new_ptolist.push({
                chassis_number: chass3.CHASSIS_NUMBER,
                pto: pto,
                load: ptoLoad,
              });
            }
          }
          dispatch(ptosettings({ settings_pto: new_ptolist }));
          setModalOpen(false);
          showSuccess(true);
        });
    });
  };

  const isRadioSelected = (value) => pto === value;

  const PTOload = () => {
    return (
      <div className="sdds-on-white-bg">
        <>
          <p style={{ marginBottom: "2rem" }}>{pto_settings[pto]}</p>
          <input
            type="text"
            id="fname"
            name="fname"
            style={{ padding: "3rem", border: "1px solid #000" }}
            placeholder={"..."}
            onChange={(e) => loadHandler(e)}
            value={ptoLoad}
          ></input>
        </>
      </div>
    );
  };

  const disableFunction = () => {
    if(pto === ""){
      return true 
    }
    if(pto === "Temperature controlled"){
      return false
    }
    else {
      if(ptoLoad !== ""){
        return false
      }
      else{
        return true
      }
    }
  };

  return (
    <>
      <div
        className="modalBackground"
        onClick={() => setModalOpen(false)}
      ></div>
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <i
              className="sdds-icon scania-cross"
              style={{
                fontSize: "6rem",
                padding: "2rem",
                cursor: "pointer",
              }}
            ></i>
          </button>
        </div>
        <div style={{ margin: "auto", display: "flex", gap: "2rem" }}>
          {mode === "all" ? (
            <p className="sdds-headline-04" style={{ margin: "auto" }}>
              PTO global calculation setting
            </p>
          ) : (
            <p className="sdds-headline-04" style={{ margin: "auto" }}>
              PTO individual settings for chassis #{chassis["CHASSIS_NUMBER"]}
            </p>
          )}
          <InfoLogo id="infolog" style={{ cursor: "pointer" }} />
          <sdds-tooltip
            placement="bottom"
            selector="#infolog"
            text={
              mode === "all"
                ? "Choose an application for all chassis in the fleet, and enter the potential load accordingly"
                : "Choose an application for this specific chassis in the fleet, and enter the potential load accordingly"
            }
          ></sdds-tooltip>
        </div>
        <div className="body">
          <div
            className={
              (pto !== "" && pto_settings[pto] !== null) ||
              ptosetting.length !== 0
                ? "innerbodyL"
                : "innerbodyFull"
            }
          >
            {Object.keys(pto_settings).map((element, idx) => {
              return (
                <div className="radiodiv" key={idx}>
                  <p>{element}</p>
                  <input
                    type="radio"
                    name="react-radio-btn"
                    value={element}
                    checked={isRadioSelected(element)}
                    onChange={(e) => onClickHandler(e)}
                    style={{ width: "7rem", height: "7rem" }}
                  ></input>
                </div>
              );
            })}
          </div>
          <div
            className={
              (pto !== "" && pto_settings[pto] !== null) ||
              (ptosetting.length !== 0 && pto_settings[pto] !== null)
                ? "loadContainer"
                : "loadHide"
            }
          >
            {PTOload()}
          </div>
        </div>
        <div className="footer">
          <button
            className="sdds-btn sdds-btn-danger sdds-btn-md button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            className="sdds-btn sdds-btn-primary sdds-btn-md button"
            onClick={() => reCalculate()}
            slot="sdds-modal-actions"
            disabled={disableFunction()}
          >
            Calculate
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalPto;
