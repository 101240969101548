import React, { useState, useEffect } from "react";
import "./ModalCharging.css";
import { useDispatch, useSelector } from "react-redux";
import { electrification, selectSettingsEl } from "../../reducers/settingsSlice";

const ModalCharging = ({ setModalOpen, showSuccess }) => {
  const dispatch = useDispatch();
  const esettings = useSelector(selectSettingsEl);

  // TODO:
  // Refactor and create more components to avoid too much duplicated code in the render method

  const [stateObject, setStateObject] = useState({
    summary: false,
    timebev1: false,
    timebev3: false,
    capacityBEV1: "",
    capacityBEV3: "",
    timeBEV1: "",
    timeBEV3: "",
  });

  const handleClickBEV1 = () => {
    setStateObject({ ...stateObject, timeBEV1: "" });
    setStateObject({ ...stateObject, timebev1: !stateObject.timebev1 });
  };

  const handleClickBEV3 = () => {
    setStateObject({ ...stateObject, timeBEV3: "" });
    setStateObject({ ...stateObject, timebev3: !stateObject.timebev3 });
  };

  const sendRequest = () => {
    // send request with the state variables
    // dispatch action to update electrification global state slice
    dispatch(electrification({ settings_electrification: stateObject }));
    setModalOpen(false);
    showSuccess(true);
  };

  useEffect(() => {
    setStateObject(esettings);
    return () => {};
  }, []);

  return (
    <>
      <div
        className="modalBackground"
        onClick={() => setModalOpen(false)}
      ></div>
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <i
              className="sdds-icon scania-cross"
              style={{
                fontSize: "6rem",
                padding: "2rem",
                cursor: "pointer",
              }}
            ></i>
          </button>
        </div>
        <div style={{ margin: "auto" }}>
          <p className="sdds-headline-04" style={{ margin: "auto" }}>
            Charging settings
          </p>
        </div>
        <div className="bodyCharging" style={{ flexDirection: "column" }}>
          <div className="innerBody">
            <div className="player">
              <div className="bev">
                <p className="sdds-headline-06" style={{ textAlign: "center" }}>
                  Top-up charging power (BEV1)
                </p>
                <input
                  type="text"
                  style={{
                    padding: "3rem",
                    border: "1px solid #000",
                    width: "100%",
                    borderRadius: "1rem",
                  }}
                  placeholder="kW"
                  onChange={(e) =>
                    setStateObject({
                      ...stateObject,
                      capacityBEV1: e.target.value,
                    })
                  }
                  value={stateObject.capacityBEV1}
                />
              </div>
              <div className="bev">
                <p className="sdds-headline-06" style={{ textAlign: "center" }}>
                  Top-up charging power (BEV3)
                </p>
                <input
                  type="text"
                  style={{
                    padding: "3rem",
                    border: "1px solid #000",
                    width: "100%",
                    borderRadius: "1rem",
                  }}
                  placeholder="kW"
                  onChange={(e) =>
                    setStateObject({
                      ...stateObject,
                      capacityBEV3: e.target.value,
                    })
                  }
                  value={stateObject.capacityBEV3}
                />
              </div>
            </div>
            <div className="player">
              {/* BEV1 charging time */}
              <div className="bev">
                <p className="sdds-headline-06" style={{ textAlign: "center" }}>
                  Custom charging time (BEV1)
                </p>
                <div className="charging">
                  <input
                    type="text"
                    style={{
                      padding: "3rem",
                      border: "1px solid #000",
                      width: "100px",
                      borderRadius: "1rem",
                    }}
                    placeholder="min"
                    disabled={!stateObject.timebev1}
                    value={stateObject.timeBEV1}
                    onChange={(e) =>
                      setStateObject({
                        ...stateObject,
                        timeBEV1: e.target.value,
                      })
                    }
                  />
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={stateObject.timebev1}
                      onChange={() => handleClickBEV1()}
                    ></input>
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* BEV3 charging time */}
              <div className="bev">
                <p className="sdds-headline-06" style={{ textAlign: "center" }}>
                  Custom charging time (BEV3)
                </p>
                <div className="charging">
                  <input
                    type="text"
                    style={{
                      padding: "3rem",
                      border: "1px solid #000",
                      width: "100px",
                      borderRadius: "1rem",
                    }}
                    placeholder="min"
                    disabled={!stateObject.timebev3}
                    value={stateObject.timeBEV3}
                    onChange={(e) =>
                      setStateObject({
                        ...stateObject,
                        timeBEV3: e.target.value,
                      })
                    }
                  />
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={stateObject.timebev3}
                      onChange={() => {
                        handleClickBEV3();
                      }}
                    ></input>
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="showSummary">
            <p>Show charging analysis summary: </p>
            <div className="sdds-checkbox-item">
              <input
                className="sdds-form-input"
                type="checkbox"
                checked={stateObject.summary}
                onChange={() =>
                  setStateObject({
                    ...stateObject,
                    summary: !stateObject.summary,
                  })
                }
              ></input>
            </div>
          </div>
          <div className="footer">
            <button
              className="sdds-btn sdds-btn-danger sdds-btn-md button"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="sdds-btn sdds-btn-primary sdds-btn-md button"
              onClick={() => sendRequest()}
              slot="sdds-modal-actions"
            >
              Calculate
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCharging;
