import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { defineCustomElements, addTheme } from "@scania/components";
import { theme as scania } from "@scania/theme-light";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./services/auth-service";

const container = document.getElementById("root");
const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);

defineCustomElements();
addTheme(scania);

if (window.location.hash !== "") {
  console.log("hash found" + window.location.hash);
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <sdds-theme name="scania"></sdds-theme>
          <BrowserRouter>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
