import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: {
    chassis: {},
    response: {},
    outputId: null,
    newBucketKey: null,
    metadata:null
  },
};

export const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    addResults: (state, action) => {
      state.response = {...action.payload.response, outputId: state.response.outputId, newBucketKey: state.response.newBucketKey, metadata: state.response.metadata};
    },
    addOutputId: (state, action) => {
      state.response.outputId = action.payload.outputId
    },
    addNewBucketKey: (state, action) => {
      state.response.newBucketKey = action.payload.newBucketKey
    },
    addMetaData: (state, action) => {
      state.response.metadata = action.payload.metadata
    }
  },
});

// actions to be dispatched for global state in seperate components
export const { addResults, addOutputId, addNewBucketKey, addMetaData } = resultsSlice.actions;

// selector for global state
export const selectResponse = (state) => state.results.response;
export const selectOutputId = (state) => state.results.response.outputId;
export const selectNewBucketKey = (state) => state.results.response.newBucketKey;
export const selectMetaData = (state) => state.results.response.metadata;

export default resultsSlice.reducer;
