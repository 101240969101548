import React from "react";
import Header from "../Header/Header";

const Home = () => {
  return (
    <div
      style={{
        backgroundColor: "#DBDFE6",
        height: "100%",
      }}
    >
      <Header />
    </div>
  );
};

export default Home;
