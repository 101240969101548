import React from "react";

const CustomizedUserFactorLabel = (props) => {
  const { x, y, fill, value } = props;
  let offset = 10;
  return (
    <text
      x={x + props.viewBox.width + offset}
      y={y + props.viewBox.height/2}
      fontSize="16"
      fontFamily="sans-serif"
      fill={fill}
    >
      {value}
    </text>
  );
};

export default CustomizedUserFactorLabel;
