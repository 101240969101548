import React from 'react'

function CustomizedTick(props) {
    const { x, y, stroke, payload, data} = props;

    let chassis_to_index_mapping = {};
    data.forEach((chassis, index) => {
        chassis_to_index_mapping[chassis.chassisnumber] = index
        return 
    })

    if(data.length !== 1) {
        if(data.length > 10){
            return (
                <g transform={`translate(${x},${y+props.height/2}) rotate(90)`}>
                    <text x={0} y={0} fill="#666">
                        <tspan textAnchor="middle" x="0">
                            {data[chassis_to_index_mapping[payload.value]].chassisnumber}
                        </tspan>
                        <tspan textAnchor="middle" x="0" dy="20">
                            {data[chassis_to_index_mapping[payload.value]].BEV_Period}
                        </tspan>
                    </text>
                </g>
            );
        }
        else {
            return (
                <g transform={`translate(${x},${y})`} >
                    <text x={0} y={0} dy={16} fill="#666">
                        <tspan textAnchor="middle" x="0">
                            {data[chassis_to_index_mapping[payload.value]].chassisnumber}
                        </tspan>
                        <tspan textAnchor="middle" x="0" dy="20">
                            {data[chassis_to_index_mapping[payload.value]].BEV_Period}
                        </tspan>
                    </text>
                </g>
            );
        }
    }
    else{
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0">
                        {data[0].chassisnumber}
                    </tspan>
                    <tspan textAnchor="middle" x="0" dy="20">
                        {data[0].BEV_Period}
                    </tspan>
                </text>
            </g>
        );
    }

  }

export default CustomizedTick