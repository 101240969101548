export function stripLeadingAndTrailingWhitespaces(string) {
    return string.replace(/(^\s+)|(\s+$)/g, '');
}

export function strOrNa(value, unit = undefined) {
    if (value == undefined || value == null || value == 0 || value == "")
        return "N/A";
    
    if (unit == undefined)
        return value;
    
    return `${value} ${unit}`;
}
