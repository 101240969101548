import React, { useState } from "react";
import ModalExport from "./ModalExport";
import { ReactComponent as DownloadLogo } from "../../images/Download.svg";

const Export = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        className="sdds-btn sdds-btn-primary modal-1 sdds-btn-md"
        style={{
          display: "flex",
          alignItems: "center",
          height: "40%",
        }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <div style={{ display: "flex", alignItems:'center'}}>
          <DownloadLogo
            style={{ margin: "0 1rem" }}
          />
          <p style={{ margin: "0 1rem" }}>Export</p>
        </div>
      </button>
      {modalOpen && <ModalExport setModalOpen={(input) => setModalOpen(input)} />}
    </>
  );
};

export default Export;
