import React, { useEffect, useState } from "react";
import Settings from "../Settings/Settings";
import { useDispatch } from "react-redux";
import { addResults, selectResponse } from "../../reducers/resultsSlice";
import "./Sheet.css";
import { useSelector } from "react-redux";
import ModalFlexibility from "./ModalFlexibility";
import ModalPto from "../Shared/ModalPto";
import Success from "../Common/Success";
import Truck from "../../images/Truck.png"
import NoTruck from "../../images/NoTruck.png"
import Chart from "../Common/Chart/Chart";
import { strOrNa } from "../../utility/stringHelpers";


const Sheet = () => {
  const dispatch = useDispatch();
  const [options, setOption] = useState([]);
  const [modalOpenFlexibility, setModalopenFlexibility] = useState(false);
  const [modalChassis, setModalChassis] = useState({});
  const [modalPTO, setModalPTO] = useState(false);
  const [successPTO, setSuccessPTO] = useState(false);
  const [chassisPTO, setchassisPTO] = useState({});
  const response = useSelector(selectResponse);  
  const [showMoreChassisinfoArray, setShowMoreChassisinfoArray] = useState(new Array(response.response.length).fill(false));
  const [minimizeCard, setMinimizeCard] = useState(new Array(response.response.length).fill(true));
  const charging_capacity_factor = 0.9;

  const calculateBevBaseRange = (dailyOdometer95thPercentile, usableEnergyKwh, totEcKwhPerKm) => {
    console.log(Math.round((dailyOdometer95thPercentile / Math.round(usableEnergyKwh / totEcKwhPerKm)) * 100))
    return Math.round((dailyOdometer95thPercentile / Math.round(usableEnergyKwh / totEcKwhPerKm)) * 100)};
  
  const sortResponse = () => {
    let sorted_res = [];
    if (options.length < 2) {
      // for now only one selectable
      switch (options[0]) {
        case "PTO":
          sorted_res = response.response
            .slice()
            .sort((a, b) => {
              console.log(a["PtoEC"])
              console.log(b["PtoEC"])
              return (b["PtoEC"] - a["PtoEC"]);
            });
          break;
        case "EC w/o PTO":
          sorted_res = response.response
            .slice()
            .sort((a, b) => b["NetEC [kWh/km]"] - a["NetEC [kWh/km]"]);
          break;
        case "Total EC":
          sorted_res = response.response
            .slice()
            .sort((a, b) => b["TotEC [kWh/km]"] - a["TotEC [kWh/km]"]);
          break;
        default:
        // default code unless no match
      }

      console.log(sorted_res);

      dispatch(
        addResults({
          response: {
            chassis: response.chassis,
            response: sorted_res,
          },
        })
      );
    } else {
      // functionality for chained sorting based on multiple values
    }
  };

  const filterResponse = () => {
    // filter by response parameters
  };

  useEffect(() => {
    //!options.every((element) => !element) && sortResponse();
    options.length !== 0 && sortResponse();
  }, [options]);

  return (
    <div name="Offers" tab-key="tab-2">
      <p className="sdds-headline-04">Offers</p>
      {/* Settings etc */}
      <Settings setOptions={(input) => setOption(input)} options={options} response = {response.response}/>
      {response.response.map((chassi, idx) => {
        if(minimizeCard[idx]) {
          return (
            <div
              className="sdds-card sdds-block__ongrey"
              style={{display:'flex', width:'100%', borderRadius:'2rem', justifyContent:'flex-start', margin:'auto auto 20px', cursor:'pointer'}}
              key={idx}
              onClick=
              {
                ()=> setMinimizeCard((prevState)=>{
                  let newArray = [...prevState];
                  newArray[idx] = !newArray[idx];
                  return newArray
                })
              }
            >
              <div
                style={{
                  padding: "2rem",
                  display:'flex',
                  flex:1,
                  justifyContent:'space-between',
                  alignItems:'center',
                  gap:'15rem',
                }}>
                  {/* Offer for chassis */}
                  <span className="sdds-headline-06" style={{fontWeight:'bold', margin:'0rem',width:'auto', height:'auto'}}>Offer for chassis #{chassi.CHASSIS_NUMBER}</span>
                  {/* BEV product specs */}
                
                  {chassi.BEV !== "No BEV product found for chassis" ? (<div style={{display:'flex',gap:'2rem', alignItems:'center', width:'auto', height:'auto'}}>
                    <img src={Truck} style={{height:'auto', width:'auto', maxHeight:'50px', maxWidth:'50px'}} alt="truck"/>
                    <span className="sdds-headline-06" style={{fontWeight:'bold', margin:'0rem'}}>{chassi["Market Name"]}</span>
                    <span style={{margin:'0rem'}}>Launch period: {chassi.Period}</span>
                    <span style={{margin:'0rem'}}>Usable energy: {chassi["Usable Energy [kWh]"]} kWh</span>
                    <span style={{margin:'0rem'}}>Max. charging power: {chassi["Max. Charging Power"]} kW</span>
                  </div>) :
                  (<div style={{display:'flex', gap:'4rem', alignItems:'center'}}>
                    <img src={NoTruck} style={{height:'auto', width:'auto', maxHeight:'50px', maxWidth:'50px', margin:'2rem 0'}} alt="truck"/>
                    <div style={{display:'flex'}}>
                      <h6 className="sdds-card-headline">
                        No BEV offer found for chassis
                      </h6>
                      <span>
                        &nbsp;-&nbsp;
                      </span>
                      <span>
                          Consider expanding this offer and adjust flexibility
                      </span>
                    </div>
                  </div>) 
                  }
                  
                  {/* Median day/Longest day */}
                  <div style={{display:'flex', gap:'15rem', alignItems:'center',width:'auto', height:'auto'}}>
                    <div>
                      <span className="sdds-headline-06" style={{fontWeight:'bold'}}>Median day: </span>
                      <span>{strOrNa(Math.round(chassi.DAILY_ODOMETER_MEDIAN), "km")}
                      {
                        chassi["Usable Energy [kWh]"] == 0 || chassi["TotEC [kWh/km]"] == 0 ?
                          null :
                          `, ${calculateBevBaseRange(chassi.DAILY_ODOMETER_MEDIAN, chassi["Usable Energy [kWh]"], chassi["TotEC [kWh/km]"])}% of BEV daily range`
                      }
                      </span>
                    </div>
                    <div>
                      <span className="sdds-headline-06" style={{fontWeight:'bold', margin:'0rem'}}>Longest day: </span>
                      <span>{strOrNa(Math.round(chassi.DAILY_ODOMETER_95), "km")}
                      {
                        chassi["Usable Energy [kWh]"] == 0 || chassi["TotEC [kWh/km]"] == 0 ?
                          null :
                          `, ${calculateBevBaseRange(chassi.DAILY_ODOMETER_95, chassi["Usable Energy [kWh]"], chassi["TotEC [kWh/km]"])}% of BEV daily range`
                      }
                      </span>
                    </div>
                  </div>
                  {/* expand */}
                  <div style={{marginLeft:'auto', display:'flex'}}>
                    {chassi.BEV === "No BEV product found for chassis" && (<button
                      className="sdds-btn sdds-btn-primary sdds-btn-sm"
                      onClick={() => {
                        setModalopenFlexibility(!modalOpenFlexibility);
                        setModalChassis(chassi);
                      }}
                      style={{padding:0, width:'130px'}}
                      >
                      For a different BEV offer..
                    </button>)}
                  </div>
              </div>
            </div>
          )
        }
        else {
          const data_chart = [
            {
              "name":"BEV daily range",
              "base range": Math.round(parseInt(chassi["Usable Energy [kWh]"]/chassi["TotEC [kWh/km]"])),
              [`additional range with 45 min topup @${chassi["Max. Charging Power"]} kW`]: Math.round(charging_capacity_factor * Math.min(chassi["Usable Energy [kWh]"], 0.75 * chassi["Max. Charging Power"]) / chassi["TotEC [kWh/km]"]),
              "median_dd": chassi["DAILY_ODOMETER_MEDIAN"],
              "longest_dd": chassi["DAILY_ODOMETER_95"],
              "tot_range": Math.round(parseInt(chassi["Usable Energy [kWh]"]/chassi["TotEC [kWh/km]"])) + Math.round(Math.min(chassi["Usable Energy [kWh]"], 0.75 * chassi["Max. Charging Power"]) / chassi["TotEC [kWh/km]"])
            }
          ];
          return <div key={idx} style={{display:'flex', width:'100%', margin:'auto'}}>
            <div
              className="sdds-card sdds-block__ongrey"
              style={{marginBottom:'30px', borderRadius:'3rem', flex:1}}
              key={idx}
            >
              <div
                style={{
                  margin: "4rem auto",
                  padding: "4rem 4rem 0 4rem",
                  borderRadius: "2rem",
                  display:'flex',
                  flex:1,
                  justifyContent:'center'
              }}>

                {/* Left-side */}
                <div 
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex:0.6,
                    justifyContent:'start',
                    flexBasis:chassi.BEV === "No BEV product found for chassis" ? 0:'auto',
                  }}>
                  {/* Header */}
                  <div
                    className="sdds-card-header"
                    style={{ display: "flex", alignItems: "center", paddingTop: 0}}
                  >
                    <p className="sdds-headline-05" style={{ margin: "0" }}>
                      Offer for chassis #{chassi.CHASSIS_NUMBER}{" "}
                      {Object.keys(chassi).some((key)=> {
                        return key.includes("UPDATED_PARAMETER") && chassi[key] !== 0;
                      }) && "- (UPDATED)"}
                    </p>
                  </div>
                  
                  {/* Card-body */}
                  <div className="sdds-card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                        gap: '2rem',
                        alignItems:'center'
                      }}
                    >
                    {chassi.BEV ===  "No BEV product found for chassis" ? (
                      <>
                        <div style={{display:'flex', gap:'4rem', justifyContent:'center', alignItems:'center'}}>
                          <img src={NoTruck} style={{height:'auto', width:'auto', maxHeight:'300px', maxWidth:'300px', margin:'2rem 0'}} alt="truck"/>
                          <div style={{width:'40%'}}>
                            <h6 className="sdds-card-headline">
                              No BEV offer found for chassis
                            </h6>
                            <p>
                              If your customer can be flexible, please use the flexibility settings by pressing “For a different BEV offer” and attempt to adjust the chassis’ properties 
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                       {/* BEV */}
                      <div
                        style={{height:'auto', width:'auto'}}
                      >
                        <img src={Truck} style={{height:'auto', width:'auto', maxHeight:'300px', maxWidth:'300px', margin:'2rem 0'}} alt="truck"/>
                        <div style={{display:'flex'}}>
                          <h6 className="sdds-card-headline">
                            BEV offer
                          </h6>
                        </div>
                          {/* {`${chassi["Market Name"] !== 0 ? `${chassi["Market Name"]}, ${chassi["Intro Year"]}, ${chassi.Intro}`: "No BEV offer is available"}`} */}
                        <p style={{margin:0}}>
                          Market name: {strOrNa(chassi["Market Name"])}
                        </p>
                        <p style={{margin:0}}>
                          Launch period: {strOrNa(chassi["Period"])}
                        </p>
                        <p style={{margin:0}}>
                          {`Usable energy: ${strOrNa(chassi["Usable Energy [kWh]"], "kWh")}`}
                        </p>
                        <p style={{margin:0}}>
                          {`Max. charging power: ${strOrNa(chassi["Max. Charging Power"], "kW")}`}
                        </p>
                      </div>

                      {/* EC */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          width:'auto',
                          height:'auto',
                          maxWidth:'200px',
                          maxHeight:'200px'
                        }}
                      >
                        <h6 className="sdds-card-headline" >Simulated energy consumption (kWh/km)</h6>

                        <div>
                          <span style={{ margin: 0, fontSize: "3rem", fontWeight:'bold'}}> NET EC: </span>
                          <span>{strOrNa(chassi["NetEC [kWh/km]"])}</span>
                        </div>

                        <div>
                          <span style={{ margin: 0, fontSize: "3rem", fontWeight:'bold'}}> PTO: </span>
                          <span> {chassi["PtoEC [kWh/km]"] === 0? "not configured" : chassi["PtoEC [kWh/km]"]} </span>
                        </div>

                        <div>
                          <span style={{ margin: 0, fontSize: "3rem", fontWeight:'bold'}}>Total EC: </span>
                          <span>{strOrNa(chassi["TotEC [kWh/km]"])} </span>
                        </div>
                      </div>

                      {/* Graph, stacked bar chart */}
                      <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'150px', height:'300px', gap:'4rem'}}>
                        <Chart data={data_chart}/>
                      </div>
                      
                      {/* Daily distance median/longest */}
                      <div
                          className="item"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <h6 className="sdds-card-headline">Chassis current daily distance: </h6>
                          <div>
                            <span> longest day: </span>
                            <span>{parseInt(chassi.DAILY_ODOMETER_95)} km, ({Math.round((parseInt(chassi.DAILY_ODOMETER_95)/parseInt(
                               Math.round(parseInt(chassi["Usable Energy [kWh]"]/chassi["TotEC [kWh/km]"]))))*100)}% of BEV daily base range)</span>
                          </div>
                          <div>
                            <span> median day: </span>
                            <span>{parseInt(chassi.DAILY_ODOMETER_MEDIAN)} km, ({Math.round((parseInt(chassi.DAILY_ODOMETER_MEDIAN)/parseInt(
                              Math.round(parseInt(chassi["Usable Energy [kWh]"]/chassi["TotEC [kWh/km]"]))))*100)}% of BEV daily base range)</span>
                          </div> 
                      </div>
                      </>
                    )}

                    </div>
                  </div>
                </div>

                {/* Right-side */}
                <div 
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex:0.4,
                    flexBasis:'auto',
                    justifyContent:'flex-start',
                  }}>

                  {/* Header */}
                  <div
                    className="sdds-card-header"
                    style={{ display: "flex", alignItems: "center", paddingTop: 0 }}
                  >
                    <p className="sdds-headline-05" style={{ margin: "0" }}>
                      Chassis spec, user factors and operation
                    </p>
                  </div>
                  
                  <div className="sdds-card-body" style={{height:'auto'}}>
                    <div style={{display:'flex', justifyContent: "space-evenly",gap:'2rem',flexWrap: "wrap"}}>
                      {/* Chassis specs */}
                      <div style={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center'
                      }}>
                        <img src={Truck} style={{height:'auto', width:'auto', maxWidth:'100px', maxHeight:'100px'}} alt="truck"/>
                        <div>
                          <p style={{fontWeight:'bold', marginBottom:'0px'}}>{`${chassi.CHASSIS_ADAPTATION.substring(0,1).toUpperCase()}${chassi.CHASSIS_ADAPTATION.substring(1,chassi.CHASSIS_ADAPTATION.length).toLowerCase()} ICE`}</p>
                          <div>
                            <>
                              <p style={{margin:'0px'}}>Assembly date: {strOrNa(chassi.ASSEMBLY_DATE)}</p>
                              <p style={{margin:'0px'}}>Wheel configuration: {strOrNa(chassi.WHEEL_CONFIGURATION)}</p>
                              <p style={{margin:'0px'}}>Axle distance: {strOrNa(chassi.AXLE_DISTANCE, "mm")}</p>
                              <p style={{margin:'0px'}}>Max GTW (technical): {strOrNa(chassi.TECHNICAL_MAX_GTW / 1000, "t")}</p>
                              <p style={{margin:'0px'}}>Chassis height: {strOrNa(chassi.CHASSIS_HEIGHT)}</p>
                            </>
                            {showMoreChassisinfoArray[idx] && (
                              <>  
                                <p style={{margin:'0px'}}>Chassis class: {strOrNa(chassi.CHASSIS_CLASS)}</p>
                                <p style={{margin:'0px'}}>Cab type: {strOrNa(chassi.CAB_TYPE)}</p>
                                <p style={{margin:'0px', marginBottom:'2rem'}}>Vehicle type: {strOrNa(chassi.VEHICLE_TYPE)}</p>
                              </>
                            )}
                            {<button onClick={()=> setShowMoreChassisinfoArray((prevState)=>{
                              let newArray = [...prevState]
                              newArray[idx] = !newArray[idx]
                              return newArray
                            })} style={{border:'none', background:'none', padding:'0px', textDecoration:'underline'}} >{ showMoreChassisinfoArray[idx] ? "less..." : "more..." }</button>}
                          </div>
                        </div>
                      </div>
                      {/* User factors */}
                      <div style={{display:'flex', flexDirection:'column', gap:'2rem', justifyContent:'center', textAlign:'center'}}>
                        <div style={{textAlign:'center'}}>
                          <span style={{fontWeight:'bold', margin:'0px'}}>Traffic:</span>
                          <p style={{margin:'0px'}}>{strOrNa(chassi.TRAFFIC)}</p>
                        </div>
                        <div style={{textAlign:'center'}}>
                          <span style={{fontWeight:'bold'}}>Topography:</span>
                          <p style={{margin:'0px'}}>{strOrNa(chassi.TOPOGRAPHY)}</p>
                        </div>
                        <div style={{textAlign:'center'}}>
                          <span style={{fontWeight:'bold'}}>Climate:</span>
                          <p style={{margin:'0px'}}>{strOrNa(chassi.CLIMATE)}</p>
                        </div>
                      </div>
                      {/* Operation */}
                      <div style={{display:'flex', flexDirection:'column', gap:'2rem', justifyContent:'center'}}>
                        <div style={{display:'flex', justifyContent:'center', gap:'2rem'}}>
                          <span style={{fontWeight:'bold', flex: 0.4, display:'flex', justifyContent:'flex-end'}}>Daily distance: </span>
                          <div style={{flex:0.6, width:'190px'}}>
                            <p style={{margin:0}}>{strOrNa(Math.round(chassi.DAILY_ODOMETER_MEDIAN), "km (median)")}</p>
                            <p style={{margin:0}}>{strOrNa(Math.round(chassi.DAILY_ODOMETER_95), "km (95th percentile)")}</p>
                          </div>
                        </div>
                        <div style={{display:'flex', gap:'2rem'}}>
                          <span style={{fontWeight:'bold', flex: 0.4, display:'flex', justifyContent:'flex-end'}}>Annual distance: </span>
                          <div style={{flex:0.6}}>
                            <p style={{margin:0}}>{strOrNa(chassi.ANNUAL_DISTANCE, "km")}</p>
                          </div>
                        </div>
                        <div style={{display:'flex', gap:'2rem'}}>
                          <span style={{fontWeight:'bold', flex: 0.4, display:'flex', justifyContent:'flex-end'}}>GTW: </span>
                          {chassi.GTW_90 == null || chassi.GTW_90 === 0 ? 
                          <div style={{flex:0.6}} >
                            <p style={{margin:0}}>{strOrNa((chassi.DAILY_AVG_GTW_95 / 1000).toFixed(2), "t (95th percentile)")}</p>
                            <p style={{margin:0}}>{strOrNa((chassi.DAILY_AVG_GTW_MEDIAN / 1000).toFixed(2), "t (median)")}</p>
                          </div>
                          :
                          <div style={{flex:0.6}}>
                            <p style={{margin:0}}>{strOrNa((chassi.GTW_90 / 1000).toFixed(2), "t (90th percentile)")}</p>
                            <p style={{margin:0}}>{strOrNa((chassi.AVG_GTW / 1000).toFixed(2), "t (avg)")}</p>
                          </div>
                          }
                          
                        </div>
                        <div style={{display:'flex', gap:'2rem'}}>
                          <span style={{fontWeight:'bold', flex: 0.4, display:'flex', justifyContent:'flex-end'}}>Application: </span>
                          <div style={{flex:0.6}}>
                            <p style={{margin:0}}>{strOrNa(chassi.APPLICATION)}</p>
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Minimize */}
              <div style={{display:'flex', justifyContent:'flex-end'}}>
                <button onClick={()=> setMinimizeCard((prevState)=>{
                  let newArray = [...prevState];
                  newArray[idx] = !newArray[idx];
                  return newArray
                })} style={{border:'none', background:'none', textDecoration:'underline', fontStyle: 'oblique', color:'gray', padding:'2rem', fontSize:'4rem'}} >minimize...</button>
              </div>
            </div>
            <div style={{display: "flex", gap: "2rem", flexDirection:'column', marginTop:'2rem' }}>
              {chassi.BEV !== "No BEV product found for chassis" && <button
                className="sdds-btn sdds-btn-primary sdds-btn-sm"
                onClick={() => (
                  setModalPTO(!modalPTO), setchassisPTO(chassi)
                )}
                style={{padding:0, width:'130px'}}
              >
                Configure PTO
              </button>}
              <button
                className="sdds-btn sdds-btn-primary sdds-btn-sm"
                onClick={() => {
                  setModalopenFlexibility(!modalOpenFlexibility);
                  setModalChassis(chassi);
                }}
                style={{padding:0, width:'130px'}}
              >
                For a different BEV offer..
              </button>
            </div> 
          </div>
        }

      })}

      {modalOpenFlexibility && (
        <ModalFlexibility
          setModalOpen={(input) => setModalopenFlexibility(input)}
          response={modalChassis}
        />
      )}
      {modalPTO && (
        <ModalPto
          setModalOpen={(input) => setModalPTO(input)}
          showSuccess={(input) => setSuccessPTO(input)}
          mode={"individual"}
          chassis={chassisPTO}
        />
      )}
      {successPTO && (
        <Success
          message={"PTO settings were successfully updated"}
          showSuccess={(input) => setSuccessPTO(input)}
        />
      )}
    </div>
  );
};

export default Sheet;
