import React, { useState } from "react";
import ModalPto from "../Shared/ModalPto";
import ModalCharging from "../Shared/ModalCharging";
import Dropdown from "../Common/Dropdown";
import { ReactComponent as SettingsLogo } from "../../images/Settings.svg";
import Success from "../Common/Success";

const Settings = ({ setOptions, options, response}) => {
  const [modalOpenPTO, setModalOpenPTO] = useState(false);
  const [modalOpenEC, setModalOpenEC] = useState(false);
  const [success, showSuccess] = useState(false); // electification
  const [successPTO, setSuccessPTO] = useState(false); // pto

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent:'space-between',
        margin: "30px auto",
        width: "100%",
      }}
    >
      {success && (
        <Success
          message={"Electrification settings were successfully updated"}
          showSuccess={(input) => showSuccess(input)}
        />
      )}
      {successPTO && (
        <Success
          message={"PTO settings were successfully updated"}
          showSuccess={(input) => setSuccessPTO(input)}
        />
      )}
      <Dropdown setOptions={setOptions} options={options} />
      <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          <p className="sdds-headline-05" style={{ margin: "0" }}>
            Global PTO settings
          </p>
          <div>
            <SettingsLogo
              aria-hidden="true"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setModalOpenPTO(true);
              }}
            />
          </div>
        </div>
        {/*
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6rem",
            justifyContent: "space-between",
          }}
        >
          <p className="sdds-headline-05" style={{ margin: "0" }}>
            Electrification settings
          </p>
          <div>
            <SettingsLogo
              aria-hidden="true"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setModalOpenEC(true);
              }}
            />
          </div>
        </div>
          */}
      </div>

      {modalOpenPTO && (
        <ModalPto 
          setModalOpen={(input) => setModalOpenPTO(input)}
          showSuccess={(input) => setSuccessPTO(input)}
          mode={"all"}
          chassis={response}
        />
      )}
      {modalOpenEC && (
        <ModalCharging
          setModalOpen={(input) => setModalOpenEC(input)}
          showSuccess={(input) => showSuccess(input)}
        />
      )}
    </div>
  );
};

export default Settings;
