import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectResponse } from "../../reducers/resultsSlice";
import Error from "../Common/Error";
import "./ModalExport.css";

const ModalExport = ({ setModalOpen }) => {
  const response = useSelector(selectResponse);
  const nr_checkboxes_format_settings = ["Excel (slim)", "Excel (raw)", "Powerpoint"];
  const nr_checkboxes_PP_settings = [
    "Recommended BEVs",
    "Average distance en-route charging",
    "Average distance, no en-route charging",
    "Longest distance en-route charging",
    "Longest distance, no en-route charging",
  ];
  const [error, setError] = useState(false);

  const [checkedStatePP, setCheckedStatePP] = useState(
    new Array(nr_checkboxes_PP_settings.length).fill(false)
  );
  const [checkedStateFormat, setCheckedStateFormat] = useState(
    new Array(nr_checkboxes_format_settings.length).fill(false)
  );

  const checkEveryFalse = () => checkedStateFormat.every((element) => !element);
  const checkEveryFalsePP = () => checkedStatePP.every((element) => !element);

  const triggerDownload = () => {
    if (checkEveryFalse() || (checkedStateFormat[nr_checkboxes_format_settings.indexOf("Powerpoint")] && checkEveryFalsePP())) {
      // Send warning message
      setError(true);
    } else if (checkedStateFormat[nr_checkboxes_format_settings.indexOf("Excel (slim)")]) {
      console.log("Downloading Excel (slim)!")
      const varxlsx = require('xlsx');

      // array of chassis objects to transform, and output in Excel file format
      let sheet = response.response.slice();
      const chassisList = [];

      for(let chassis of sheet){
        let chassisObject = {...chassis}
        let keysToDelete = ["CUSTOMER_COUNTRY", "CUSTOMER_MARKET", "DEVELOPMENT_LEVEL", "CHASSIS_CLASS", "ENGINE_TYPE", "INDEX", "SEQ", "SUSPENSION_SYSTEM_REAR",
                            "TAIL_LIFT", "VALUE", "TECHNICAL_MAX_GTW", "Installed Energy [kWh]", "No of battery packs",
                            "SOC-Window", "Battery Setup", "Add. weight for BEV [kg]"]

        for (let key of keysToDelete) {
          delete chassisObject[key]
        }

        for (let key of Object.keys(chassisObject)) {
          if (key.startsWith('bev_product') || key.startsWith('UPDATED')) {
            delete chassisObject[key]
          }
        }

        for (let key of Object.keys(chassisObject)) {          
          if (key !== null && (key.includes('DAILY_ODOMETER_Q1') || key.includes('DAILY_ODOMETER_Q3')) && chassisObject[key] !== 0) {
            let newKey = key.replace("Q1", "25").replace("Q3", "75")
            chassisObject[newKey] = chassisObject[key]
            delete chassisObject[key]
            key = newKey
          }

          if (key.includes('_') || key === key.toUpperCase()) {
            let subStrings = key.split('_')
            for (let i = 0; i < subStrings.length; i++) {
              subStrings[i] = subStrings[i].charAt(0).toUpperCase() + subStrings[i].slice(1).toLowerCase()
            }
            let newKey = subStrings.join(' ')
            chassisObject[newKey] = chassisObject[key]
            delete chassisObject[key]
            //console.log("newkey",newKey)
          }
          if (key.includes('BEV Cab Types') && chassisObject[key] !== 0) {
            chassisObject[key] = chassisObject[key].join(", ")
          }
          
        }
        console.log("chassis object", chassisObject)
        chassisList.push(chassisObject)
      }
      let sortedHeader = ["Chassis Number", "Customer Name", "Assembly Date", "Application", "Vehicle Type", "Chassis Adaptation",
                    "Wheel Configuration", "Axle Distance", "Cab Type", "Chassis Height", "Annual Distance", "Avg Gtw",
                    "Gtw 90", "Avg Speed", "Climate", "Topography", "Traffic", 
                    "Daily Odometer 05", "Daily Odometer 25", "Daily Odometer Median", "Daily Odometer 75","Daily Odometer 95",
                    "Bev", "Market Name", "Period", "Intro", "BEV Cab Types", "Usable Energy [kWh]", "NetEC [kWh/km]", "PtoEC [kWh/km]", "TotEC [kWh/km]",
                    "Used Energy, median [kWh]", "Used Energy, longest [kWh]", "Required battery percentage, median [%]",
                    "Required battery percentage, longest [%]"]

      let worksheet = varxlsx.utils.json_to_sheet(chassisList, {header: sortedHeader});
        // Create a new Workbook
      let wb = varxlsx.utils.book_new()

      // Name your sheet
      varxlsx.utils.book_append_sheet(wb, worksheet, 'FACT_OUTPUT_SLIM')

      // export your excel
      varxlsx.writeFile(wb, `FACT_EXPORT_SLIM_${new Date().toISOString().slice(0, -5)}.xlsx`);
      console.log("Finished downloading");

      setModalOpen(false);

    } else {
      //alert("Downloading ...");
      const varxlsx = require('xlsx');

      // array of objects to save in Excel
      let copyResponse = response.response.slice();
      const binary = [];

      for(let obj of copyResponse){
        let newObj = {...obj};
        for(let key of Object.keys(newObj)){
          if(key.includes("bev_product") && newObj[key] !== 0){
            let transformed_object = {
              "Axle distance": {
                "evaluation": newObj[key][0][0],
                "bev_product": newObj[key][0][1],
                "chassis": newObj[key][0][2]
              },
              "GTW": {
                "evaluation": newObj[key][1][0],
                "bev_product": newObj[key][1][1],
                "chassis": newObj[key][1][2]
              },

              "Wheel configuration": {
                "evaluation": newObj[key][2][0],
                "bev_product": newObj[key][2][1],
                "chassis": newObj[key][2][2]
              },

              "Chassis height": {
                "evaluation": newObj[key][3][0],
                "bev_product": newObj[key][3][1],
                "chassis": newObj[key][3][2]
              },
            }
            newObj[key] = JSON.stringify({...transformed_object});
          }
        }
        binary.push(newObj);
      }
      console.log(binary);

      let binaryWS = varxlsx.utils.json_to_sheet(binary);
      // Create a new Workbook
      let wb = varxlsx.utils.book_new()

      // Name your sheet
      varxlsx.utils.book_append_sheet(wb, binaryWS, 'FACT_OUTPUT')

      // export your excel
      varxlsx.writeFile(wb, `FACT_EXPORT_${new Date().toISOString().slice(0, -5)}.xlsx`);
      console.log("Finished downloading");

      setModalOpen(false);
      // Send another request to backend with the new settings if powerpoint settings differ from default,
      // and if excel manipulate the dataframe from response (json->excel)and download.
      // Download file, check
    }
  };

  const handleChangePP = (position) => {
    const updatedCheckedPPSettingsState = checkedStatePP.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedStatePP(updatedCheckedPPSettingsState);
  };

  const handleChangeFormat = (position) => {
    const updatedCheckedFormatState = checkedStateFormat.map((item, index) => {
      return index === position ? !item : item;
    });
    setCheckedStateFormat(updatedCheckedFormatState);

    if (nr_checkboxes_format_settings[position] === "Powerpoint") {
      if (!checkedStateFormat.at(position)) {
        let response = new Array(nr_checkboxes_PP_settings.length).fill(true);
        setCheckedStatePP(response);
      } else {
        setCheckedStatePP(
          new Array(nr_checkboxes_PP_settings.length).fill(false)
        );
      }
    }
  };

  return (
    <>
      {error && (
        <Error
          setRequestError={(error) => setError(!error)}
          header={checkEveryFalse() ? "Select option" : "Powerpoint settings"}
          paragraph={
            checkEveryFalse()
              ? "Choose an option to download output as"
              : "Choose at least one powerpoint setting to show in the powerpoint"
          }
        />
      )}
      <div
        className="modalBackground"
        onClick={() => setModalOpen(false)}
      ></div>
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <i
              className="sdds-icon scania-cross"
              style={{
                fontSize: "6rem",
                padding: "2rem",
                cursor: "pointer",
              }}
            ></i>
          </button>
        </div>

        <p className="sdds-headline-04" style={{ margin: "auto", color:'black'}}>
          Export settings
        </p>

        <div className="body">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "4rem",
              width: "100%",
              margin: "10rem",
            }}
          >
            {/* Excel */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
              }}
            >
              <h5 slot="sdds-modal-headline" style={{ textAlign: "center" }}>
                Select export format
              </h5>
              <div
                style={{
                  margin: "4rem 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                {nr_checkboxes_format_settings.map((element, idx) => {
                  return (
                    <div className="sdds-checkbox-item" key={idx}>
                      <input
                        className="sdds-form-input"
                        id="cb-option-1"
                        type="checkbox"
                        defaultChecked={checkedStateFormat[idx]}
                        disabled={nr_checkboxes_format_settings[idx] === "Powerpoint"}
                        onChange={() => handleChangeFormat(idx)}
                      />
                      <label
                        className="sdds-form-label"
                        htmlFor="cb-option-2"
                        style={{ marginRight: "auto" }}
                      >
                        <p>{element}</p>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Powerpoint */}
            {
              <div style={{ flex: 0.5 }}>
                <h5 slot="sdds-modal-headline" style={{ textAlign: "center" }}>
                  Powerpoint settings
                </h5>
                <div
                  style={{
                    margin: "4rem 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                  }}
                >
                  {nr_checkboxes_PP_settings.map((element, idx) => {
                    return (
                      <div className="sdds-checkbox-item" key={idx}>
                        <input
                          className="sdds-form-input"
                          id="cb-option-1"
                          type="checkbox"
                          disabled={
                            !checkedStateFormat.at(
                              nr_checkboxes_format_settings.indexOf(
                                "Powerpoint"
                              )
                            )
                          }
                          checked={checkedStatePP[idx]}
                          onChange={() => handleChangePP(idx)}
                        />
                        <label
                          className="sdds-form-label"
                          htmlFor="cb-option-2"
                          style={{ marginRight: "auto" }}
                        >
                          <p>{element}</p>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            }
          </div>
        </div>

        <div className="footer">
          <button
            className="sdds-btn sdds-btn-danger sdds-btn-md button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            className="sdds-btn sdds-btn-primary sdds-btn-md button"
            onClick={() => triggerDownload()}
            slot="sdds-modal-actions"
            disabled={checkEveryFalse()}
          >
            Download
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalExport;
