import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedin: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state) => {
      state.loggedin = true;
    },
    logout: (state) => {
      state.loggedin = false;
    },
  }
});

// actions to be dispatched for global state in seperate components
export const { login, logout } = loginSlice.actions;

// selector for global state
export const selectLogin = (state) => state.login.loggedin;


export default loginSlice.reducer;