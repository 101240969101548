import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../Common/Container";
import Error from "../Common/Error";
import { stripLeadingAndTrailingWhitespaces } from "../../utility/stringHelpers";
import { useDispatch } from "react-redux";
import {addResults, addOutputId, addNewBucketKey, addMetaData} from "../../reducers/resultsSlice";
import { add } from "../../reducers/chassisSlice";
import { loginRequest } from "../../services/auth-service";
import ModalCharging from "../Shared/ModalCharging";
import Spinner from "../Common/Spinner";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { callMsGraph } from "../../services/msGraphApi";
import country_market_mappings from "../../utility/countryMapping";

const ChassisInput = () => {
  const navigation = useNavigate();
  const [chassisError, setchassisError] = useState(false);
  const [chassisLengthError, setChassisLengthError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [modalOpenPTO, setModalOpenPTO] = useState(false);
  const [modalOpenEC, setModalOpenEC] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [valauthChassis, setValAuthChassis] =  useState({});
  const [chassisValError, setChassisValError] =  useState(false);
  const [chassisAuthError, setChassisAuthError] =  useState(false);

  const textArea = useRef("");
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const retrySheetCall = (url, bucketPath, id_token) => {
    const axiosClient = axios.create();
    const retryCondition = (error) => {
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        error.response.status === 404
      );
    };

    axiosRetry(axiosClient, {
      retries: 200,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition,
    });

    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${id_token}`,
        bucket_path: bucketPath,
      },
    });
  };

  const handleClick = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Strip away meaningless values and create a list seperated by either comma or whitespace using regex
    const chassis = stripLeadingAndTrailingWhitespaces(textArea.current.value).split(/[ ,\n]+/);

    // Validation for chassis input, check that each token is a number 0-9 and each element that has been seperated by , or "" or newline, is 7 token long...
    const pass = chassis.every(function (element, idx) {
      return stripLeadingAndTrailingWhitespaces(element).match(/^[0-9]+$/) != null && stripLeadingAndTrailingWhitespaces(element).length === 7;
    });

    const lengthEvaluation = chassis.length <= 1000

    if(!pass){
      setchassisError(true) 
      setSpinner(false)
      // Drop the request altogether
      return;
    }

    if(!lengthEvaluation) {
      setChassisLengthError(true)
      setSpinner(false)
      // Drop the request altogether
      return
    }

    let uniq_stripped = [];
    let uniq = [...new Set(chassis)].forEach((chassis_number)=>{
      let stripped_chassis = stripLeadingAndTrailingWhitespaces(chassis_number);
      if(stripped_chassis !== ""){
        uniq_stripped.push(stripped_chassis)
      }
    })
    uniq_stripped = uniq_stripped.map((chassis_number) => parseInt(chassis_number))

    //console.log(uniq_stripped.join())
    dispatch(add({ uniq: uniq }));
    // Send POST request to FACT backend API with chassis as requestparameters
    let endpoint = process.env.REACT_APP_FACT_API_GW_URL;
    const url = endpoint + "/Prod/CreateSheet";

    // Getting the token
    pass &&
      instance.acquireTokenSilent(request).then((response) => {
        callMsGraph(response.accessToken).then(response => response);

        //console.log("idToken response with JWT", response)
        const user_role = response.idTokenClaims.roles ?? [];

        //console.log("user roles:", user_role)

        let id_token = response.idToken;
        //console.log(response.idTokenClaims.ctry)
        let country_name = country_market_mappings['country_iso_mapping'][response.idTokenClaims.ctry]
        let global_name = response.idTokenClaims.preferred_username.split("@")[0];

        let request_body = uniq_stripped.join()
        let user_role_internal = user_role.includes("FACT-internal");

        const CreateChassisRequest = axios.create();
        CreateChassisRequest.post(
          url,
          { request_body },
          {
            headers: user_role_internal ? {
              Authorization: `Bearer ${id_token}`,
              global_name: global_name,
            } : {
              Authorization: `Bearer ${id_token}`,
              global_name: global_name,
              country_name: country_name
            }
          }
        ).then((res) => {
          //console.log("metadata",res.data.metadata)

          const invalidated_chassis = uniq_stripped.filter(x => !res.data.metadata.validated_chassis.includes(x));
          const unauthorized_chassis = res.data.metadata.validated_chassis.filter(x => !res.data.metadata.authorized_chassis.includes(x));
          const validated_chassis = res.data.metadata.validated_chassis;
          const authorized_chassis = res.data.metadata.authorized_chassis;

          //console.log("validated chassis", validated_chassis)
          //console.log("unauthorized_chassis", unauthorized_chassis)
          setValAuthChassis({unauthorized_chassis, invalidated_chassis, validated_chassis, nr_unauth:unauthorized_chassis.length, 
            nr_valid:validated_chassis.length, all_chassis: uniq_stripped, nr_chassis:uniq_stripped.length})

          //console.log(res.data.output_id);
          // store the outputId as global state
          dispatch(addOutputId({ outputId: res.data.output_id }));
          dispatch(addNewBucketKey({ newBucketKey: global_name + "/" + res.data.output_id + "/data.json" }));
          dispatch(addMetaData({metadata:{authorized_chassis:authorized_chassis, validated_chassis:validated_chassis, invalidated_chassis:invalidated_chassis, unauthorized_chassis:unauthorized_chassis}}))

          let bucketPath =
            global_name + "/" + res.data.output_id + "/data.json";
          let url = endpoint + "/Prod/GetSheet";
          
          retrySheetCall(url, bucketPath, id_token).then((res) => {
            if (res.data.hasOwnProperty("message")) {
              // TODO:
              // Add state for checking if there is no chassis data available.
              //console.log("Response getSheet:", res);
            } else {
              //console.log(res.data);
              dispatch(addResults({ response: res.data }));
              navigation("/analysis");
            }
          });
        }).catch((error) => {
          //console.log(error)
          if(error.message === "Network Error"){
            setAuthError(true);
            setSpinner(false);
          }
          else if(error.response.status === 403){
            //console.log("inside 403")
            // if no authorized chassis can be found
            setChassisAuthError(true);
            setSpinner(false);
          }
          else if(error.response.status === 404){
            //console.log("inside 404")
            // if no validated chassis can be found
            setChassisValError(true);
            setSpinner(false);
          }
        });
      });
  };

  return (
    <Container>
      {chassisError && (
        <Error
          setRequestError={(chassisError) => setchassisError(!chassisError)}
          header={"Must input correct chassis numbers!"}
          paragraph={
            "Please enter the correct chassis numbers that you wish to analyze. Chassis numbers should be seperated by comma or whitespace."
          }
        />
      )}
      {chassisLengthError && (
        <Error
          setRequestError={(chassisLengthError) => setChassisLengthError(!chassisLengthError)}
          header={"Too many chassis numbers in input!"}
          paragraph={
            "FACT3 currently supports input of up to 1000 chassis numbers."
          }
        />
      )}
      {authError && (
        <Error
          setRequestError={(authError) => setAuthError(!authError)}
          header={"Must be authorized to be able to run FACT!"}
          paragraph={
            "Not authorized - Please contact Jakob Petersén (jakob.petersen@scania.com) to apply for access to FACT."
          }
        />
      )}
      {chassisAuthError && (
        <Error
          setRequestError={(chassisAuthError) => setChassisAuthError(!chassisAuthError)}
          header={"No authorized chassis found!"}
          paragraph={
            "Please try with chassis that are owned by customers in your market."
          }
        />
      )}
      {chassisValError && (
        <Error
          setRequestError={(chassisValError) => setChassisValError(!chassisValError)}
          header={"No validated chassis found!"}
          paragraph={
            "Data missing for all requested chassis."
          }
        />
      )}
      <div
        className="sdds-card sdds-block__ongrey"
        style={{ padding: "4rem", width: "50%", borderRadius: "2rem" }}
      >
        {/* {<ProfileContent />} */}
        <div className="sdds-row">
          <div className="sdds-on-white-bg sdds-col-xs-12">
            <p className="sdds-headline-03"> Input chassis list </p>
            <div style={{ margin: "auto" }}>
              <sdds-textarea
                ref={textArea}
                placeholder="Enter chassis number"
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "4rem",
                justifyContent: "flex-end",
                margin: "4rem",
              }}
            >
              {/* Electrification settings */}
              {/*
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                <p className="sdds-headline-05" style={{ margin: "0" }}>
                  PTO settings
                </p>
                <SettingsLogo
                  aria-hidden="true"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalOpenPTO(true);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                <p className="sdds-headline-05" style={{ margin: "0" }}>
                  Electrification settings
                </p>
                <SettingsLogo
                  aria-hidden="true"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalOpenEC(true);
                  }}
                />
              </div>
                */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="sdds-btn sdds-btn-primary sdds-btn-md"
                style={{
                  margin: "4rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setchassisError(false), setSpinner(true), handleClick();
                }}
              >
                {!spinner ? (
                  "Analyze fleet and find BEV offers"
                ) : !chassisError ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>Analyze fleet and find BEV offers</p>
                    <Spinner />
                  </div>
                ) : (
                  "Analyze fleet and find BEV offers"
                )}
              </button>
            </div>
            {/* Display metadata */}
             {Object.keys(valauthChassis).length !== 0 ? 
              ( 
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: 'rgb(219, 223, 230)'}}>
                  <div style={{margin:'15px'}}>
                    {valauthChassis["nr_valid"] !== 0 && <p style={{margin:0}}><span style={{fontWeight:'bold'}}>Number of found chassis:</span> {valauthChassis["nr_valid"]}/{valauthChassis["nr_chassis"]} ({valauthChassis.validated_chassis.slice(0,5).join()}{`${valauthChassis.validated_chassis.length > 4 ? valauthChassis.validated_chassis.length-5 !== 0 ? ` ..and ${valauthChassis.validated_chassis.length-5} more` : "" : ""}`})</p>}
                    {valauthChassis["invalidated_chassis"].length !== 0 && <p style={{margin:0}}><span style={{fontWeight:'bold'}}> Number of chassis not found:</span> {valauthChassis["invalidated_chassis"].length}/{valauthChassis["nr_chassis"]} ({valauthChassis.invalidated_chassis.slice(0,5).join()} {`${valauthChassis.invalidated_chassis.length > 4 ? valauthChassis.invalidated_chassis.length-5 !== 0 ? ` ..and ${valauthChassis.invalidated_chassis.length-5} more` : "" : ""}`})</p>}
                    {valauthChassis["nr_unauth"] !== 0 && <p style={{margin:0}}><span style={{fontWeight:'bold'}}> Number of unauthorized chassis:</span> {valauthChassis["nr_unauth"]}/{valauthChassis["nr_chassis"]} ({valauthChassis.unauthorized_chassis.slice(0,5).join()} {`${valauthChassis.unauthorized_chassis.length > 4 ? valauthChassis.unauthorized_chassis.length-5 !== 0 ? ` ..and ${valauthChassis.unauthorized_chassis.length-5} more` : "" : ""}`})</p>}
                  </div>
                </div>
              )
              : (null)
              }
          </div>
        </div>

        {modalOpenPTO && (
          <ModalSettings setModalOpen={(input) => setModalOpenPTO(input)} />
        )}
        {modalOpenEC && (
          <ModalCharging setModalOpen={(input) => setModalOpenEC(input)} />
        )}
      </div>
    </Container>
  );
};

export default ChassisInput;
