import React, {useState, useEffect} from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
  Label,
} from "recharts";
import CustomBarWithTarget from "./CustomBarWithTarget";

const Chart = ({ data }) => {
  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: "black" }}>{value}</span>;
  };

  const [position, setPosition] = useState(null);
  let offset = 100;

  console.log("data", Object.keys(data[0])[2])
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={730}
        height={250}
        data={data}
        onMouseMove={(data) => {console.log(data), setPosition({ data: data.activeCoordinate, show: true })}}
        onMouseLeave={(data) =>
          setPosition({ data: data, show: false })
        }
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"/>
        <YAxis type="number" domain={[0, data[0]["longest_dd"] > data[0]["tot_range"] ? (Math.round(data[0]["longest_dd"] + offset)) : data[0]["tot_range"]]}>
            <Label value='km' angle="-90" position='insideLeft' ></Label>
        </YAxis>
        <Tooltip 
          position={{x: 0, y: -100}} 
          cursor={false} 
          content={<CustomTooltip />
          }/>
        <Legend
          verticalAlign="bottom"
          formatter={renderColorfulLegendText}
          align="left"
          wrapperStyle={{bottom: 0, left: 50}}
          width={300}
        />
        <Bar dataKey="base range" stackId="a" fill="#041E42" />
        <Bar dataKey={Object.keys(data[0])[2]} stackId="a" fill="#E6AF2E" shape={<CustomBarWithTarget additional_top_up_key_name = {Object.keys(data[0])[2]} useSecond={true} />} median_dd={data.median_dd} longest_dd={data.longest_dd}>
          <LabelList dataKey="tot_range" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
        backgroundColor:'hsl(270 3% 29% / 0.9)', color:'white', width:'150%', borderRadius:'5px', padding:'3rem'}}>
        <span style = {{padding:0}}>
          <span style={{fontWeight:'bold'}}>Total range: </span> {payload[0]["payload"]["tot_range"]} km
        </span>
        <span style = {{padding:0}}>
          <span style={{fontWeight:'bold'}}>Base range: </span> {payload[0]["payload"]["Base range"]} km
        </span>
        <span style={{padding:0}}>
          <span style={{fontWeight:'bold'}}>Additional range with 45 min top up:</span> {payload[0]["payload"]["additional range with 45 min topup"]} km
        </span>
      </div>
    );
  }
};

export default Chart;