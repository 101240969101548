import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { selectOutputId, addResults, addNewBucketKey } from "../../reducers/resultsSlice";
import Spinner from "../Common/Spinner";
import { loginRequest } from "../../services/auth-service";
import axios from "axios";
import "./ModalFlexibility.css";
import axiosRetry from "axios-retry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const ModalFlexibility = ({ setModalOpen, response }) => {
  const outputId = useSelector(selectOutputId);
  const dispatch = useDispatch();

  const [spinner, setSpinner] = useState(false);
  const [BEVproduct, setBEVproduct] = useState([]);
  const [BEVoffer, setBEVoffer] = useState("");
  const [showHardLimit, setShowHardLimit] = useState(true);
  const { instance, accounts } = useMsal();

  const retrySheetCall = (url, bucketPath, id_token) => {
    const axiosClient = axios.create();

    const retryCondition = (error) => {
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        error.response.status === 404
      );
    };

    axiosRetry(axiosClient, {
      retries: 200,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition,
    });

    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${id_token}`,
        bucket_path: bucketPath,
      },
    });
  };

  const reCalculate = (response, bevIndex, hard_limit) => {
    let endpoint = process.env.REACT_APP_FACT_API_GW_URL;

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then((res) => {
      const url = endpoint + "/Prod/UpdateSheet";

      let id_token = res.idToken;
      let global_name = res.idTokenClaims.preferred_username.split("@")[0];

      const request_body = [
        {
          chassis_number: response.CHASSIS_NUMBER,
          new_bev_offer: `bev_product_${bevIndex}`, 
          hard_limit: hard_limit,
          flexibility: [],
        }
      ];

      request_body[0]["flexibility"].push(createFlexibilityChange("WHEEL_CONFIGURATION", "wheel-configuration-" + bevIndex));
      request_body[0]["flexibility"].push(createFlexibilityChange("AXLE_DISTANCE", "axle-distance-" + bevIndex));
      request_body[0]["flexibility"].push(createFlexibilityChange("CHASSIS_HEIGHT", "chassis-height-" + bevIndex));

      const bucketPrefix = global_name + "/" + outputId;
      const UpdateChassisFlexSettings = axios.create();
      UpdateChassisFlexSettings.patch(
        url,
        { request_body },
        {
          headers: {
            Authorization: `Bearer ${id_token}`,
            bucket_prefix: bucketPrefix,
          },
        }
      ).then((res) => {
        dispatch(addNewBucketKey({ newBucketKey: res.data.updated_bucket_key}))
        let endpoint = process.env.REACT_APP_FACT_API_GW_URL;
        const url = endpoint + "/Prod/GetSheet";
        retrySheetCall(url, res.data.updated_bucket_key, id_token).then((res) => {
          dispatch(addResults({ response: res.data }));
          setSpinner(false);
          setModalOpen(false);
        })
      });
    });
  };

  const createFlexibilityChange = (name, id) => {
    let dropdownValue = document.getElementById(id).getAttribute("selected-value")

    return {
      parameter: name,
      new_value: name === "AXLE_DISTANCE" ? parseInt(dropdownValue) : dropdownValue,
      old_value: response[name]
    }
  }

  useEffect(() => {
    const number_BEV_intros = () => {
      let counter = 0;
      Object.keys(response).map((key)=>{
        if (key.includes("bev_product")) {
          counter += 1;
        }
      })
      setBEVproduct(new Array(counter).fill(false))
    };
    number_BEV_intros()
  }, []);

  useEffect(()=> {
    findBEVoffer();
  }, [])

  const listValues = (bev_values) => {
    const sddsOptions = bev_values.map((value, index) => (
      <sdds-dropdown-option key={index} value={String(value)}>
        {value}
      </sdds-dropdown-option>
    ));
    return sddsOptions;
  };

  const findBEVoffer = () => {
    response["BEV offer"] !== 0 && setBEVoffer(response["BEV offer"]);
  };

  const determineIfChassisSatiesfiesHardLimits = (key, bev_info) => {
    let gtw = response.GTW_90 == null || response.GTW_90 === 0 ? response.DAILY_AVG_GTW_95 : response.GTW_90
    return ((bev_info[7] !== 0 ? response.DAILY_ODOMETER_MEDIAN > bev_info[7] : false) || gtw > response[key][1][1])
  };

  const setBevProductState = (idx, key, bev_info) => {
    setBEVproduct((prevState)=> {
      let newState = [...prevState]
      let idx_true = prevState.indexOf(true);
      if (idx_true >= 0) {
        newState[idx_true] = false
      }
      !(showHardLimit && determineIfChassisSatiesfiesHardLimits(key, bev_info)) ? (newState[idx] = true) : null;
      return newState
    })
  }

  const listBEVoffers = () => {
    let determineHardLimitBevProducts = []
    return (
      <div style={{display:'flex', flexDirection:'column', gap:'10px', }}>
        {Object.keys(response).map((key)=>{
          if (key.includes("bev_product") && response[key] !== 0) {
            let splitted_array = key.split("_");
            let idx = splitted_array[splitted_array.length-1]
            let bev_info = response[key][response[key].length-1];

            console.log("bev info", bev_info)
            //console.log("bev product", response[key])

            // Chassis specific data
            let chassis_wc = response[key][2][2];
            let chassis_ad = response[key][0][2];
            let chassis_ch = response[key][3][2];
            // BEV specific data
            let bev_wc = response[key][2][1];
            let bev_ad = response[key][0][1];
            let bev_ch = response[key][3][1];

            determineIfChassisSatiesfiesHardLimits(key, bev_info) ? determineHardLimitBevProducts.push(bev_info[0]) : null; 

            return (
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', paddingRight:'40px', gap:'10px'}}>
                {/* Arrow pointing to current offer */}
                <div style={{visibility: key === BEVoffer ? "visible" : "hidden"}}>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ margin: "0 2rem", color: '#1DAB8B', fontSize:'30px'}}
                    />
                </div>
                <div 
                  id={(showHardLimit && determineIfChassisSatiesfiesHardLimits(key, bev_info)) ? `card-${idx}` : null}
                  style={{
                    display:'flex', 
                    height:!BEVproduct[idx] ? `40px` : undefined, 
                    width:'100%', 
                    justifyContent:'space-between', 
                    alignItems:'center', 
                    padding:'20px',
                    borderColor: BEVproduct[idx] && '#0000ff', borderStyle:BEVproduct[idx] && 'solid', borderWidth: BEVproduct[idx] && 'thin',
                    borderRadius:'5px', 
                    backgroundColor: (showHardLimit && determineIfChassisSatiesfiesHardLimits(key, bev_info) && key !== BEVoffer) ? '#FA8072':'#F8F8F8',
                    cursor: (showHardLimit && determineIfChassisSatiesfiesHardLimits(key, bev_info)) ? "auto" :  key !== BEVoffer ? "pointer" : "auto"
                  }}
                    onClick = {()=> {
                      if (key !== BEVoffer) {
                        if(showHardLimit) {
                          if(!determineHardLimitBevProducts.includes(key)) {
                            setBevProductState(idx, key, bev_info)
                          }
                        }
                        else {
                          setBevProductState(idx, key, bev_info)
                        }
                      }
                  }}
                  >
                    {!BEVproduct[idx] ? (
                      <div id={`card-${idx}`} style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
                        <p style={{width:'18%'}}>
                          <span style={{fontWeight:'bold'}}>BEV: </span> 
                          <span>{bev_info[1]}</span>
                        </p>
                        <p>
                          <span style={{fontWeight:'bold'}}>Launch Period: </span> 
                          <span>{bev_info[5]}</span>
                        </p>
                        <p>
                          <span style={{fontWeight:'bold'}}>Usable Energy: </span> 
                          <span>{bev_info[4]}</span> kWh
                        </p>
                        <p>
                          <span style={{fontWeight:'bold'}}>Max charging power: </span> 
                          <span>{bev_info[6]}</span> kW 
                        </p>
                      </div>
                      ) 
                    : 
                    (
                      <div style={{width:'100%'}}>
                        {/* Greater version */}
                        <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                          <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}> 
                            <span>
                              <span style={{fontWeight:'bold'}}>BEV: </span> 
                              <span>{bev_info[1]}</span>
                            </span>
                            <span>
                              <span style={{fontWeight:'bold'}}>Launch Period: </span> 
                              <span>{bev_info[5]}</span>
                            </span>
                            <span>
                              <span style={{fontWeight:'bold'}}>Usable Energy: </span> 
                              <span>{bev_info[4]}</span> kWh
                            </span>
                            <span>
                              <span style={{fontWeight:'bold'}}>Max charging power: </span> 
                              <span>{bev_info[6]}</span> kW
                            </span>
                          </div>

                          <p>In order to select this BEV product as an offer for the evaluated chassis, the following properties needs to be adjusted:</p>

                          <div style={{display:'flex', justifyContent:'space-between'}}>
                            {/* Dropdown options */}
                            <div style={{display:'flex', flexDirection:'column'}}>
                              <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                <span style={{fontWeight:'bold', whiteSpace: 'nowrap'}}>
                                  Wheel configuration: 
                                </span> 
                                <span>{chassis_wc}</span>
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ margin: "0 2rem" }}
                                />
                                <sdds-dropdown
                                  id={`wheel-configuration-${idx}`}
                                  size="small"
                                  style={{width:'125px', padding:0}}
                                  type="multiselect"
                                  default-option={String(bev_wc[0])}
                                >
                                  {listValues(bev_wc)}
                                </sdds-dropdown>
                              </div>
                              <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'flex-start'}}>
                                <span style={{fontWeight:'bold', whiteSpace: 'nowrap'}}>
                                Axle distance:  
                                </span> 
                                {chassis_ad}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ margin: "0 2rem" }}
                                />
                                <sdds-dropdown
                                  id={`axle-distance-${idx}`}
                                  size="small"
                                  style={{width:'125px', padding:0}}
                                  type="multiselect"
                                  default-option={String(bev_ad[0])}
                                >
                                  {listValues(bev_ad)}
                                </sdds-dropdown>
                              </div>
                              <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'flex-start'}}>
                                <span style={{fontWeight:'bold', whiteSpace: 'nowrap'}}>
                                  Chassis height: 
                                </span> 
                                <span>{chassis_ch}</span>
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ margin: "0 2rem" }}
                                />
                                <sdds-dropdown
                                  id={`chassis-height-${idx}`}
                                  size="small"
                                  style={{width:'125px', padding:0}}
                                  type="multiselect"
                                  default-option={String(bev_ch[0])}
                                >
                                  {listValues(bev_ch)}
                                </sdds-dropdown>
                              </div>
                            </div>
                            {/* Accept button */}
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                              <button
                                className={"sdds-btn sdds-btn-primary sdds-btn-md button"}
                                style={{zIndex:300}}
                                onClick={() => (reCalculate(response, idx, !showHardLimit && determineHardLimitBevProducts.includes(key) ? true : false), setSpinner(true))}
                                slot="sdds-modal-actions"
                              >
                                {spinner ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap:'2rem'
                                    }}
                                  >
                                    Accept
                                    <Spinner />
                                  </div>
                                ) : (
                                  "Accept"
                                )}
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                </div>
              
                {
                  (showHardLimit && determineIfChassisSatiesfiesHardLimits(key, bev_info) && key !== BEVoffer) ? 
                  <sdds-tooltip
                    placement="right"
                    selector={`#card-${idx}`} 
                    style={{backgroundColor:'white'}}
                  >
                    {
                      <div>
                        <p style={{fontWeight:"bold"}}>{"BEV offer unavailable for this chassis!"}</p>
                        {response.DAILY_ODOMETER_MEDIAN > bev_info[7] && bev_info[7] !== 0 && <p style={{color:'red'}}>{`- Median daily distance <= ${bev_info[7]} km `}</p>}
                        {(response.DAILY_AVG_GTW_95) > response[key][1][1] && <p style={{color:'red'}}>{`- GTW (95th percentile) <= ${response[key][1][1]} kg`}</p>}
                      </div>
                    }
                  </sdds-tooltip>
                  : 
                  // To make the width of bev product item div tags the same as those with tooltips on the same line
                  <div/>
                }
                </div>  
            )
          }
        })}
      </div>
    )
  };

  return (
    <>
      <div
        className="modalBackground"
        onClick={() => setModalOpen(false)}
      ></div>

      <div className="somethingElse">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <i
              className="sdds-icon scania-cross buttonStyling"
              style={{
                fontSize: "6rem",
                padding: "2rem",
                cursor: "pointer",
              }}
            ></i>
          </button>
        </div>

        <div className="body" style={{marginTop:'0px'}}>
          <p className="sdds-headline-04" style={{width:'450px', textAlign:'center', fontWeight:'bold'}}>
            Select another BEV offer, and be flexible in the evaluation of the chassis
          </p>
          
          {/* List all the BEV products */}
          <div style={{display:'flex', flexDirection:'column', gap:'2rem', width:'100%', height:'100%'}}>
            <div style={{display:'flex', justifyContent:'space-between', width:'95%'}}>
              <span style={{width:'50px'}}>
                Current offer:
              </span>
              <div>
                <div class="sdds-toggle" tabindex="0">
                  <label class="sdds-toggle-label" for="customSwitch1">Hard limits for daily distance and GTW &nbsp; </label>
                  <input onClick={()=>(setShowHardLimit(!showHardLimit), setBEVproduct(new Array(BEVproduct.length).fill(false)))} type="checkbox" class="sdds-toggle-input" id="customSwitch1" checked={showHardLimit}/>
                  <span class="sdds-toggle-switch"></span>
                </div>
              </div>
            </div>
            <div>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', paddingRight:'40px', gap:'10px'}}>
                {/* Arrow pointing to current offer */}

                {BEVoffer === "" && BEVproduct.length !== 0 && 
                <>
                  <div style={{visibility : BEVoffer === "" ? "visible" : "hidden"}}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ margin: "0 2rem", color:'#1DAB8B', fontSize:'30px'}}
                      />
                  </div>
                  <div style={{display:'flex', gap:'10px', height:'40px', width:'100%', backgroundColor:'#F8F8F8', justifyContent:'start', alignItems:'center', padding:'5px', marginBottom:'10px'}}>
                    <span>
                      <span style={{fontWeight:'bold', padding:'20px'}}>No offer found for chassis </span> 
                    </span>
                  </div>
                </>
                }
              </div>
              {listBEVoffers()}
            </div> 
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalFlexibility;