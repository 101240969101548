import React from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/auth-service";
import { useIsAuthenticated } from "@azure/msal-react";

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  // const loginStatus = useSelector(selectLogin);
  const navigate = useNavigate();

  const handleClick = (instance) => {
    //console.log(isAuthenticated)
    !isAuthenticated
      ? instance.loginPopup(loginRequest)
      .then(response => {
        //console.log(response); 
        navigate("/input")
      })
      .catch((e) => {
          console.error(e);
          navigate("/");
        })
      : instance.logoutPopup().then(response => navigate("/")).catch((e) => {
        console.error(e);
        });
  };
  const { instance } = useMsal();

  return (
    <>
      <button
        onClick={() => {
          handleClick(instance);
        }}
        className="sdds-btn sdds-btn-primary sdds-btn-md"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40%",
        }}
      >
        {!isAuthenticated ? "Log in" : "Log out"}
      </button>
    </>
  );
};

export default Login;
