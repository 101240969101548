import React from "react";

const Success = ({ message, showSuccess }) => {
  return (
    <div
      className="sdds-message sdds-message__type-success sdds-message__icon-active sdds-message__extended-active sdds-message-ongrey"
      style={{
        position: "fixed",
        bottom: "4rem",
        left: "6rem",
        height:'6%',
        width:'25%',
        zIndex: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <svg
          className="sdds-message-icon sdds-message-icon--success"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3418 5.29948C17.5867 5.5428 17.5879 5.93853 17.3446 6.18336C15.1603 8.3811 13.4132 10.1263 11.9367 11.5992C11.6684 11.8668 11.4091 12.1254 11.1578 12.3761C10.0256 13.5051 9.05514 14.4729 8.15387 15.3793C8.03673 15.4971 7.87752 15.5634 7.71139 15.5636C7.54526 15.5638 7.38589 15.4978 7.26848 15.3803L2.67198 10.7787C2.42804 10.5345 2.42826 10.1388 2.67247 9.89483C2.91668 9.65089 3.31241 9.6511 3.55635 9.89532L7.70995 14.0535C8.48846 13.2726 9.32605 12.4374 10.2759 11.4901C10.5271 11.2397 10.7861 10.9814 11.0539 10.7142C12.5298 9.24199 14.2755 7.49815 16.458 5.3022C16.7013 5.05737 17.097 5.05615 17.3418 5.29948Z"
            fill="#1DAB8B"
          ></path>
        </svg>
      </div>

      <h4 className="sdds-message-single">{message}</h4>
      <i
        className="sdds-icon scania-cross"
        style={{
          position: "absolute",
          fontSize: "6rem",
          padding:'4rem',
          right: 1,
          top: 1,
          padding: "2rem",
          cursor: "pointer",
        }}
        onClick={()=>showSuccess(false)}
      ></i>
    </div>
  );
};

export default Success;
