import React, {useState, useEffect} from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList
} from "recharts";
import CustomBarWithTarget from "../../Common/Chart/CustomBarWithTarget";
import CustomizedTick from "../../Common/Chart/CustomizedTick";

const DailyRangeChart = ({ data }) => {
  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: "black", fontStyle:"italic" }}>{value}</span>;
  };

  const sortedData = data.sort((a,b)=>a.tot_range - b.tot_range);
  //console.log("sorted", sortedData)
  const offset_y_axis = 100;
  const barChartWidth = 600;
  const barChartHeight = 730

  const [position, setPosition] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  useEffect(()=>{
   setMaxValue(Math.max(...data.map((chassis) => (chassis.longest_dd))) +offset_y_axis)
  }, [])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={barChartWidth} height={barChartHeight} data={sortedData} margin={{ top: 5, right: 10, bottom: 20, left: 5 }} maxBarSize={100} 
        onMouseMove={(data) => {console.log(data),setPosition({data: data.activeCoordinate, show: true })}}
        onMouseLeave={(data) =>
          setPosition({ data: data, show: false }) }>

        <CartesianGrid strokeDasharray="3 3" />
        <YAxis dataKey="tot_range" type="number" domain={[0, maxValue]}>
          <Label value="km" position="insideLeft" offset={0}></Label>
        </YAxis>
        <XAxis tick={<CustomizedTick data={sortedData}/>} height={data.length > 10 ? 100 : 30} dataKey={'chassisnumber'}>
        </XAxis>
        <Tooltip position={{x: (position?.data?.x - barChartWidth/3) ?? 0, y: -100}} 
          cursor={false} 
          content={<CustomTooltip />}
        />
        <Legend verticalAlign="bottom" formatter={renderColorfulLegendText} wrapperStyle={{bottom:'-15px'}}/>
        <Bar dataKey="Base range" stackId="a" fill="#041E42" />
        <Bar dataKey="additional range with 45 min topup" stackId="a" fill="#E6AF2E" shape={<CustomBarWithTarget additional_top_up_key_name = "additional range with 45 min topup"/>} median_dd={data.median_dd} longest_dd={data.longest_dd}>
          <LabelList dataKey="tot_range" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
        backgroundColor:'hsl(270 3% 29% / 0.9)', color:'white', width:'100%', borderRadius:'5px', padding:'3rem'}}>
        <span style = {{padding:0}}>
          <span style={{fontWeight:'bold'}}>Total range: </span> {payload[0]["payload"]["tot_range"]} km
        </span>
        <span style = {{padding:0}}>
          <span style={{fontWeight:'bold'}}>Base range: </span> {payload[0]["payload"]["Base range"]} km
        </span>
        <span style={{padding:0}}>
          <span style={{fontWeight:'bold'}}>Additional range with 45 min top up:</span> {payload[0]["payload"]["additional range with 45 min topup"]} km
        </span>
      </div>
    );
  }
};

export default DailyRangeChart;
