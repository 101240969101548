import React from "react";
import Header from "../Header/Header";

const Container = (props) => {
  const { children } =  props;
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Header />
      <div
        className="sdds-block"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#DBDFE6",
          flex: 1,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
