import React, { useEffect, useState, useRef} from "react";
import { selectResponse, selectMetaData} from "../../reducers/resultsSlice";
import { useSelector } from "react-redux";
import { ReactComponent as InfoLogo } from "../../images/Info.svg";
import UserFactorChart from "./Charts/UserFactorChart";
import PieChartBEV from "./Charts/PieChartBEV";
import DailyRangeChart from "./Charts/DailyRangeChart";
import A4 from "../../images/A4.png"
import A6 from "../../images/A6.png"
import A8 from "../../images/A8.png"
import B4 from "../../images/B4.png"
import B6 from "../../images/B6.png"

const Summary = () => {
  const response = useSelector(selectResponse);  
  const metadata =  useSelector(selectMetaData);

  const [UserFactorData, setUserFactorData] = useState({topoData:null, trafficData:null, climateData:null});
  const [BEVProductData, setBEVProductData] = useState([]);
  const [BEVenergyDemand, setBEVEnergyDemand] = useState([]);
  const [BEVDailyRange, setBEVDailyRange] = useState([]);
  const [BEVmetadata, setBEVmetadata] =  useState({chassis_list_found_offers:[], chassis_list_not_found_offers:[]});

  const charging_capacity_factor = 0.9;

  const temp_conversion = {
    "VeryCold": "Cold",
    "Cold": "Cold",
    "Mixed": "Mixed",
    "MixedWide": "Mixed",
    "Hot": "Warm",
    "VeryHot": "Warm"
  }
    
  const topo_conversion = {
    "Flat": "Mostly flat",
    "Hilly": "Hilly",
    "Very hilly": "Very Hilly",
    "Very Hilly": "Very Hilly"
  }
  
  const traffic_conversion = {
    "Fluid Traffic": "Country - Light traffic",
    "Light Traffic": "Country - Light traffic",
    "Residential Traffic": "City - Moderate traffic",
    "Dense Traffic": "Regional - Mixed traffic",
    "Start/Stop": "Regional - Mixed traffic",
    "Very Freq. Start/Stop": "City - Very frequent start/ stop"
  }

  useEffect(()=>{
    // Fetch the relevant data for user factor charts...
    getUserFactorsData()
    getBEVdata()
    getDailyRange()
    getBEVmetadata()
  }, [response])

  const getDailyRange = () => {
    let data_chart = []
    response.response.forEach((chassis)=> {
      if(chassis.BEV !== "No BEV product found for chassis"){
        let baseRange = Math.round((chassis["Usable Energy [kWh]"]/chassis["TotEC [kWh/km]"]))
        let additionalRange = Math.round(charging_capacity_factor * Math.min(chassis["Usable Energy [kWh]"], 0.75 * chassis["Max. Charging Power"]) / chassis["TotEC [kWh/km]"])
  
        data_chart.push({"Base range": baseRange, "additional range with 45 min topup": additionalRange, "chassisnumber": chassis["CHASSIS_NUMBER"], "BEV_Period": `(BEV ${chassis["Period"]})`, tot_range: (baseRange + additionalRange), median_dd: chassis["DAILY_ODOMETER_MEDIAN"], longest_dd: chassis["DAILY_ODOMETER_95"]});
        setBEVDailyRange(data_chart);
      }
    })
  };

  const getBEVmetadata = () => {
    let found_offers_chassis =  [];
    let not_found_offers_chassis =  [];
    response.response.forEach((chassis)=> {
      chassis["BEV"] !== "No BEV product found for chassis" ? found_offers_chassis.push(chassis.CHASSIS_NUMBER) : not_found_offers_chassis.push(chassis.CHASSIS_NUMBER);
   })

    setBEVmetadata({
      chassis_list_found_offers: found_offers_chassis, 
      chassis_list_not_found_offers: not_found_offers_chassis
    })
  };

  const getBEVdata = () => {
    let dict =  {};
    let energy_demand_dict = {};
    let BEV_array = [];
    response.response.forEach((chassis)=>{
      let name = `${chassis['Market Name']} BEV ${chassis['Period']} ${chassis['Usable Energy [kWh]']}`;
      chassis.BEV !== "No BEV product found for chassis" ? name in dict ? dict[name] = dict[name] + 1 : dict[name] = 1 : null;
      if(chassis.BEV !== "No BEV product found for chassis"){
        Object.keys(energy_demand_dict).includes(name) ? (energy_demand_dict[name]['energy_median'] += chassis['Used Energy, median [kWh]'], energy_demand_dict[name]['energy_longest'] += chassis['Used Energy, longest [kWh]'])
        :(Object.assign(energy_demand_dict, {[name]: {'energy_median' : chassis['Used Energy, median [kWh]'], 'energy_longest': chassis['Used Energy, longest [kWh]'], wc:chassis.WHEEL_CONFIGURATION,
      ca: chassis.CHASSIS_ADAPTATION}}));
      }
    })
    
    Object.entries(dict).map(([key, value])=> {BEV_array.push({name:key, value: value})})
    setBEVProductData(BEV_array);
    setBEVEnergyDemand(energy_demand_dict);

  };


  const getUserFactorsData = () => {
    const traffic_array = []; 
    const climate_array = [];
    const topo_array = [];

    const traffic_dict = {}; 
    const climate_dict = {};
    const topo_dict = {};

    response.response.forEach((chassis)=>{
      chassis.TOPOGRAPHY !== 0 ? (Object.keys(topo_dict).includes(topo_conversion[chassis.TOPOGRAPHY]) ? topo_dict[topo_conversion[chassis.TOPOGRAPHY]] = topo_dict[topo_conversion[chassis.TOPOGRAPHY]] + 1: topo_dict[topo_conversion[chassis.TOPOGRAPHY]] = 1) : null; 
      chassis.CLIMATE !== 0 ? (Object.keys(climate_dict).includes(temp_conversion[chassis.CLIMATE]) ? climate_dict[temp_conversion[chassis.CLIMATE]] = climate_dict[temp_conversion[chassis.CLIMATE]] + 1: climate_dict[temp_conversion[chassis.CLIMATE]] = 1) : null; 
      chassis.TRAFFIC !== 0 ? (Object.keys(traffic_dict).includes(traffic_conversion[chassis.TRAFFIC]) ? traffic_dict[traffic_conversion[chassis.TRAFFIC]] = traffic_dict[traffic_conversion[chassis.TRAFFIC]] + 1: traffic_dict[traffic_conversion[chassis.TRAFFIC]]  = 1): null; 
    });
    Object.entries(traffic_dict).map(([key,value])=>{traffic_array.push({name:key, count:value})})
    Object.entries(climate_dict).map(([key,value])=>{climate_array.push({name:key, count:value})})
    Object.entries(topo_dict).map(([key,value])=>{topo_array.push({name:key, count:value})})
    setUserFactorData({topoData:topo_array, trafficData:traffic_array, climateData:climate_array})
  }

  const generatePerformanceSteps = () => {
    const electrification_years_mappings =  {};
    BEVProductData.forEach((BEV_offer) => {
      let year = `20${BEV_offer.name.split(" ").slice(-2, -1)[0].split(".")[0]}`
      Object.keys(electrification_years_mappings).includes(year) ? electrification_years_mappings[year].push(BEV_offer)
      : electrification_years_mappings[year] = [BEV_offer];
      }
    )    
    const offset = 500-200
    let total_energy_demand_per_electrification_step_median = 0;
    let total_energy_demand_per_electrification_step_longest = 0;
    
    return (
      <>
        <div style={{display:'flex', justifyContent:'center', width:'100%', alignItems:'center', height:'500px', gap:'50px'}}>
          {Object.entries(electrification_years_mappings).map(([year, offers], year_idx) => {
            let number_of_steps = Object.keys(electrification_years_mappings).length
            let periods = [];
            
            return(
              <div style={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'center'}}>
                <div style={{display:'flex', gap:'2rem', marginBottom:'5px'}}>
                  {/* Div with images of trucks */}
                  {offers.map((offer) => {
                    return (
                      <div style={{maxWidth:'100%'}}>
                        {BEVenergyDemand[offer["name"]]["wc"][0] === "4" && BEVenergyDemand[offer["name"]]["ca"][0].toUpperCase() === "A" && (
                          <img src={A4} style={{height:'auto', width:'auto', maxHeight:'80px', maxWidth:'80px'}} alt="truck"/>
                        )}
                        {BEVenergyDemand[offer["name"]]["wc"][0] === "6" && BEVenergyDemand[offer["name"]]["ca"][0].toUpperCase() === "A" && (
                          <img src={A6} style={{height:'auto', width:'auto', maxHeight:'80px', maxWidth:'80px'}} alt="truck"/>
                        )}
                        {BEVenergyDemand[offer["name"]]["wc"][0] === "8" && (BEVenergyDemand[offer["name"]]["ca"][0].toUpperCase() === "A" || 
                        BEVenergyDemand[offer["name"]]["ca"][0].toUpperCase() === "B"  ) && (
                          <img src={A8} style={{height:'auto', width:'auto', maxHeight:'80px', maxWidth:'80px'}} alt="truck"/>
                        )}
                        {BEVenergyDemand[offer["name"]]["wc"][0] === "4" && BEVenergyDemand[offer["name"]]["ca"][0].toUpperCase() === "B" && (
                          <img src={B4} style={{height:'auto', width:'auto', maxHeight:'80px', maxWidth:'80px'}} alt="truck"/>
                        )}
                        {BEVenergyDemand[offer["name"]]["wc"][0] === "6" && BEVenergyDemand[offer["name"]]["ca"][0].toUpperCase() === "B" && (
                          <img src={B6} style={{height:'auto', width:'auto', maxHeight:'80px', maxWidth:'80px'}} alt="truck"/>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div key={year_idx} style={{borderLeft:'5px solid #041E42', borderTop:'5px solid #041E42', padding:'20px', height:'200px', marginBottom:`${number_of_steps !== 1 ? ((year_idx+1)/number_of_steps)*offset :0.5*offset }px`}}>
                  <p style={{textAlign:'center', marginBottom:'15px', marginTop:'15px'}} className="sdds-headline-03">{year} </p>
                  {offers.map((offer, idx) => {
                    periods.push(offer["name"].split(" ").slice(-2, -1).join(""))
                    console.log("value", offer["value"])
                    console.log(BEVenergyDemand)
                    console.log(BEVenergyDemand[offer["name"]])
                    console.log(Math.round(BEVenergyDemand[offer["name"]]["energy_median"]))
                    total_energy_demand_per_electrification_step_median += Math.round(BEVenergyDemand[offer["name"]]["energy_median"])
                    total_energy_demand_per_electrification_step_longest += Math.round(BEVenergyDemand[offer["name"]]["energy_longest"])
                    return (
                      <div >
                        {/* Div with electrification year related information */}
                        {!periods.includes(offer["name"].split(" ").slice(-2, -1).join("")) && (<p style={{textDecoration:'underline'}} className="sdds-headline-06">{offer["name"].split(" ").slice(-2, -1)}</p>)}
                        <span style={{marginTop:0, marginBottom:'10px'}}>{`${offer["value"]} ${offer["name"]} kWh`}</span>
                        <div style={{marginBottom:'10px'}}>
                          <p style={{marginBottom:0, marginTop:'10px'}} className="sdds-headline-06" >Daily energy demand:</p>
                          <p style={{marginTop:0,marginBottom:0}}> {`Median day: ${Math.round(BEVenergyDemand[offer["name"]]["energy_median"])} kWh`} </p>
                          <p style={{marginTop:0,marginBottom:0}}> {`Worst day: ${Math.round(BEVenergyDemand[offer["name"]]["energy_longest"])} kWh`} </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
            </div>
            )
          })}
        </div>
        <div style={{display:'flex', justifyContent:'center'}}>
          {energyDemandForEntireFleet(total_energy_demand_per_electrification_step_median, total_energy_demand_per_electrification_step_longest)}
        </div>
      </>
      )
  };

  const energyDemandForEntireFleet = (energy_median, energy_longest) => {
    return (
      <p style={{marginTop:'-50px'}} className="sdds-headline-05">Total energy demand for fully transformed fleet (median/longest): {`${Math.round(energy_median)}/${Math.round(energy_longest)}`} kWh</p>
    )
  }

  return (
    <div name="Summary" tab-key="tab-1">
      <div style={{display:'flex', flexDirection:'column', gap:'4rem'}}>
        <p className="sdds-headline-04">Summary</p>
        {BEVDailyRange.length !== 0 && 
        ( 
          <>
            <div style={{display:'flex', gap:'8rem',justifyContent:'center', gap:'100px', alignItems:'center', height:'500px'}}>
              {/* BEV product offers */}
              <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', height:'100%', marginTop:'50px'}}>
                <span style={{textAlign:'center'}} className="sdds-headline-04">BEV product offers </span>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'500px', height:'400px', gap:'4rem'}} className={"container"}>
                  <PieChartBEV data={BEVProductData} />
                </div>
              </div>
              
              {/* BEV daily range */}
              <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', height:'100%', marginTop:'50px'}}>
                <span style={{textAlign:'center'}} className="sdds-headline-04">BEV daily range </span>
                <div style={{display:'flex', alignItems:'center', width:`${BEVDailyRange.length > 5 ? '1000px' : '500px'}`, height:'1000px', gap:'4rem', marginBottom:'10px', marginRight: '45px'}} className={"container"}>
                  <DailyRangeChart data={BEVDailyRange} />
                </div>

                <svg style={{marginTop:'20px'}}>
                  <text x={200} y={10} fontStyle={"italic"}>Median daily distance </text>
                  <text x={200} y={30} fontStyle={"italic"}>Longest daily distance</text>
                  <line x1={70} x2={150} y1={10} y2={10} stroke={"#2967c1"} strokeWidth={2} strokeDasharray={"10 5"}/>
                  <line x1={70} x2={150} y1={30} y2={30} stroke={"#964B00"} strokeWidth={2} strokeDasharray={"1100 2"}/>
                </svg>
              </div>
            </div>
            <div className="sdds-divider-light" style={{margin: '5px', width: '100%', margin: '10rem 0'}}></div> 
        </>
          )
        }
        {/* Energy performance steps */}
          <div style={{width:'100%'}}>
          <p style={{textAlign:'center', marginBottom:'50px'}} className="sdds-headline-04"> Electrification roadmap </p>
          {/* Create a function that generates the multiple div performance steps along with the information */}
          {generatePerformanceSteps()}
        </div>
        <div style={{display:'flex', gap:'8rem',justifyContent:'center',  gap:'100px', alignItems:'center', height:'300px'}}>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', height:'100%',marginTop:'50px'}}>
            <p style={{textAlign:'center'}} className="sdds-headline-04"> Analysis metadata </p>
            <div>
              {/* BEV/Data meta data */}
              <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <span><span style={{fontWeight:'bold'}}>BEV offers for:</span> {`${BEVmetadata.chassis_list_found_offers.length}/${BEVmetadata.chassis_list_found_offers.length +
                  BEVmetadata.chassis_list_not_found_offers.length}`} </span>
                
                <InfoLogo id={"bev_offers_for"} pointerEvents="all" className={"tooltip"} style={{ cursor: "pointer"}}></InfoLogo>
                <sdds-tooltip
                    placement="bottom"
                    selector={"#bev_offers_for"} 
                  >
                  {<span> {BEVmetadata.chassis_list_found_offers.length !== 0 ? (BEVmetadata.chassis_list_found_offers.join()) : "N/A"} </span>}
                </sdds-tooltip>
              </div>

              <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                {console.log(metadata)}
                <span><span style={{fontWeight:'bold'}}>Data missing for: </span> {`${metadata.invalidated_chassis.length}/${metadata.invalidated_chassis.length + metadata.validated_chassis.length}`}</span>
                <InfoLogo id={"data_missing"} pointerEvents="all" className={"tooltip"} style={{ cursor: "pointer"}}></InfoLogo>
                <sdds-tooltip
                    placement="bottom"
                    selector={"#data_missing"} 
                  >
                  {metadata.invalidated_chassis.length !== 0 ? metadata.invalidated_chassis.join() : "N/A"}
                </sdds-tooltip>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <span><span style={{fontWeight:'bold'}}>Unauthorized chassis found: </span> {metadata.unauthorized_chassis.length}</span>
                <InfoLogo id={"unauthorized_chassis"} pointerEvents="all" className={"tooltip"} style={{ cursor: "pointer"}}></InfoLogo>
                <sdds-tooltip
                    placement="bottom"
                    selector={"#unauthorized_chassis"} 
                  >
                  {metadata.unauthorized_chassis.length !== 0 ? metadata.unauthorized_chassis.join() : "N/A"}
                </sdds-tooltip>
              </div>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', height:'100%', marginTop:'50px'}}>
            <p style={{textAlign:'center'}} className="sdds-headline-04">Chassis user factors </p>
            <div style={{display:'flex', gap:'2rem', marginRight:'100px'}}>
              {/* User factors metedata  */}
              {/* Climate */}
              <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'300px', height:'200px'}} className={"container"}>
                <UserFactorChart data={UserFactorData.climateData}/>
              </div>
              {/* Topography */}
              <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'300px', height:'200px'}} className={"container"}>
                <UserFactorChart data={UserFactorData.topoData}/>
              </div>
              {/* Traffic conditions */}
              <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'300px', height:'200px'}} className={"container"}>
                <UserFactorChart data={UserFactorData.trafficData}/>
              </div>
            </div>
          </div>
        </div>

        <div className="sdds-divider-light" style={{margin: '5px', width: '100%', margin: '10rem 0'}}></div> 
      </div>
    </div>
  );
};

export default Summary;
