import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chassis: [],
};

export const chassisSlice = createSlice({
  name: "chassis",
  initialState,
  reducers: {
    add: (state, action) => {
      state.chassis = action.payload.uniq;
    },
  },
});

// actions to be dispatched for global state in seperate components
export const { add } = chassisSlice.actions;

// selector for global state
export const selectChassis = (state) => state.chassis.chassis;

export default chassisSlice.reducer;
