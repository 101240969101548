import React from 'react';

const CustomBarWithTarget = (props) => {
    const { fill, x, y, width, height, median_dd, longest_dd, useSecond, additional_top_up_key_name } = props;

    // props[0] refers to the additional range with top-up

    let targetYMedian = y + ((height/props[additional_top_up_key_name]) * (props.value[1]-median_dd))
    let targetYLongest = y + ((height/props[additional_top_up_key_name]) * (props.value[1]-longest_dd))

    let xCalculation = useSecond ? x + 20 : x + width / 3;

    return <svg>
        <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill}/>
        <text x={xCalculation} y={targetYMedian-3} fontStyle={"italic"} fill={"white"}>{Math.round(median_dd)}</text>
        <text x={xCalculation} y={targetYLongest-3} fontStyle={"italic"} fill={"white"}>{Math.round(longest_dd)}</text>
        <line x1={x - 8} x2={x + (width + 8)} y1={targetYMedian} y2={targetYMedian} stroke={"#2967c1"} strokeWidth={2} strokeDasharray={"10 5"}/>
        <line x1={x - 8} x2={x + (width + 8)} y1={targetYLongest} y2={targetYLongest} stroke={"#964B00"} strokeWidth={2} strokeDasharray={"1100 2"}/>
    </svg>;
};

export default CustomBarWithTarget