import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings_pto: [],
  settings_electrification: {
    summary: false,
    timebev1: false,
    timebev3: false,
    capacityBEV1: "",
    capacityBEV3: "",
    timeBEV1: "",
    timeBEV3: "",
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    ptosettings: (state, action) => {
      state.settings_pto = action.payload.settings_pto;
    },
    electrification: (state, action) => {
      state.settings_electrification = action.payload.settings_electrification;
    },
  },
});

// actions to be dispatched for global state in seperate components
export const { ptosettings, electrification } = settingsSlice.actions;

// selector for global state
export const selectSettingsPTO = (state) => state.settings.settings_pto;
export const selectSettingsEl = (state) =>
  state.settings.settings_electrification;

export default settingsSlice.reducer;
