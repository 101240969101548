import * as Msal from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_OIDC_CLIENTID,
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    redirectUri: `${window.location.origin}/callback`,
    knownAuthorities: [process.env.REACT_APP_OIDC_AUTHORITY]
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add here scopes for access token to be used at MS Graph API endpoints.
const tokenRequest = {
  scopes: ["User.Read"],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",

  
};

export default class AuthService {
  constructor() {
    console.log("[AuthService.constructor] started constructor");
    // Create the main app instance
    // configuration parameters are located at authConfig.js
    this.app = new Msal.PublicClientApplication(msalConfig);

    this.signInType = "loginRedirect"; // 'loginPopup' or 'loginRedirect'
  }

  init = async () => {
    console.log(`[AuthService.init] started init ${this.app.getAccount()}`);

    try {
      const tokenResponse = await this.app.handleRedirectPromise();
      logger.message(
        `[AuthService.init] tokenResponse ${JSON.stringify(tokenResponse)}`
      );
      const accountObj = tokenResponse
        ? tokenResponse.account
        : this.app.getAccount();
      if (accountObj) {
        logger.message(`[AuthService.init] id_token acquired ${accountObj}`);
        await this.getTokenRedirect(loginRequest);
      } else if (tokenResponse && tokenResponse.tokenType === "Bearer") {
        logger.message("[AuthService.init] access_token acquired");
      } else if (tokenResponse === null) {
        // tokenResponse was null, attempt sign in or enter unauthenticated state for app
        logger.message("[AuthService.init] signIn");
        await this.signIn();
      } else {
        logger.message(
          `[AuthService.init] tokenResponse was not null but did not have any tokens: ${tokenResponse}`
        );
      }
    } catch (error) {
      logger.message(
        `AuthService.js handleRedirectPromise error, ${error}`,
        "error"
      );
    }
  };

  signIn = async () => {
    console.log(`[AuthService.signIn] signInType: ${this.signInType}`);
    if (this.signInType === "loginRedirect") {
      console.log("kfgg");
      try {
        const loginResponse = await this.app.loginPopup(loginRequest);
        console.log(loginResponse);
        this.login = loginResponse;

        let base64Payload = this.getIdToken().split(".")[1];
        let payload = Buffer.from(base64Payload, "base64");
        let decodedAndParsedString = JSON.parse(payload.toString());
        let email = decodedAndParsedString["preferred_username"];
        let username = email.slice(0, -11);
      } catch (error) {
        //logger.message('[AuthService.signIn] loginPopup error', error)
        console.log(error);
      }
    } else if (this.signInType === "loginRedirect") {
      this.app.loginRedirect(loginRequest);
    }
    return null;
  };

  signOut = () => {
    this.app.logoutPopup();
  };

  getAccessToken = () => {
    if (this.login) return this.login.accessToken;
    return null;
  };

  getIdToken = () => {
    if (this.app.getAccount()) return this.app.getAccount().idToken;
    return null;
  };

  getTokenPopup = async () => {
    try {
      const response = await this.app.acquireTokenSilent(loginRequest);
      logger.message(
        `[AuthService.getTokenPopup] loginResponse: ${JSON.stringify(response)}`
      );
      return response;
    } catch (error) {
      logger.message("silent token acquisition failed", "error");
      if (error instanceof Msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        logger.message("acquiring token using popup", "debug");
        try {
          await this.app.acquireTokenPopup(loginRequest);
        } catch (err) {
          logger.message(`auth-service.js getTokenPopup: ${err}`, "error");
        }
      }
      logger.message(`auth-service.js getTokenPopup: ${error}`, "error");
    }
    return null;
  };

  getProfile = async () => {
    if (!this.app.getAccount()) return null;

    let response;
    if (this.signInType === "loginPopup") {
      response = await this.getTokenPopup(loginRequest);
    } else {
      response = await this.getTokenRedirect(loginRequest);
    }

    const user = await this.callMSGraph(
      graphConfig.graphMeEndpoint,
      response.accessToken
    );
    return user;
  };
}

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}