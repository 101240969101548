import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CustomizedUserFactorLabel from "../../Common/Chart/CustomizedUserFactorLabel";

const UserFactorChart = ({ data }) => {
  return (
    <ResponsiveContainer width={350}>
      <BarChart
        width={350}
        height={100}
        data={data}
        layout="vertical"
        barCategoryGap={3}
        margin={{right: 30 }}
      >
        <XAxis type="number" />

        <YAxis
          dataKey="name"
          type="category"
          width={200}
        />

        <Bar
          dataKey="count"
          fill="#041E42"
          label={<CustomizedUserFactorLabel />}
          isAnimationActive={false}
        />
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default UserFactorChart;
