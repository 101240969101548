import React from "react";
import Home from "./components/Home/Home";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import ChassisInput from "./components/ChassisInput/ChassisInput";
import Settings from "./components/Settings/Settings";
import FactSheet from "./components/FactSheet/FactSheet";
import {
  AuthenticatedTemplate,
} from "@azure/msal-react";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/input"
          element={
            <AuthenticatedTemplate>
              <ChassisInput />
            </AuthenticatedTemplate>
          }
        />
        <Route
          path="/settings"
          element={
            <AuthenticatedTemplate>
              <Settings />
            </AuthenticatedTemplate>
          }
        />
        <Route
          path="/analysis"
          element={
            <AuthenticatedTemplate>
              <FactSheet />
            </AuthenticatedTemplate>
          }
        />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
